
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import CategoryForm from '@/components/Forms/ResourcesV2/CategoryFormV2.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import NewCategoryTypeDialog from '@/components/Modals/Dialogs/ResourcesV2/NewCategoryTypeDialogV2.vue'
import { ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import { ICategoryTypeV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import Edit from '@/components/DataTable/Edit.vue'

export default defineComponent({
  name: 'CategoryEditV2',
  data() {
    return {
      categoryTypeColumns: [
        {
          key: 'type',
          title: this.mixWB('W: Type'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewCategoryTypeDialog: shallowRef(NewCategoryTypeDialog),
    }
  },
  computed: {
    ...mapGetters([
      'categoriesAsArrayV2',
      'typesAsArrayV2',
    ]),
    categoryToEdit(): ICategoryV2 {
      const { categoryID } = this.$route.params

      return this.categoriesAsArrayV2.find((x: ICategoryV2) => x.id === categoryID)
    },
    categoryTypeRows(): Row[] {
      const categoryTypes = this.categoryToEdit.getCategoryTypes()

      return categoryTypes.reduce((prev: Row[], categoryType: ICategoryTypeV2) => {
        prev.push({
          id: categoryType.id,
          type: categoryType.getTypeTitle(),
          typeID: categoryType.typeID,
          title: categoryType.getTitle(),
        })
        return prev
      }, [])
    },
  },
  methods: {
    onAddCategoryTypeClick() {
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    CategoryForm,
    Button,
    Dialog,
    DataTable,
    Edit,
  },
})
