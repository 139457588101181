
import DataTable from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewCategoryDialog from '@/components/Modals/Dialogs/ResourcesV2/NewCategoryDialogV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { IAreaV2 } from '@/types/resources-version-2'
import { sortBy } from 'lodash-es'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CategoriesV2',
  data() {
    return {
      columns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'categoryTypes',
          title: this.mixWB('W: Kategori-typer'),
          canSort: true,
        },
        {
          key: 'askForPlaster',
          title: this.mixWB('W: Spørg om puds'),
          canSort: true,
        },
        {
          key: 'askForWallpaper',
          title: this.mixWB('W: Spørg om tapet'),
          canSort: true,
        },
        {
          key: 'isSummaryCategory',
          title: this.mixWB('W: Har opsummering'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewCategoryDialog: shallowRef(NewCategoryDialog),
    }
  },
  computed: {
    ...mapGetters([
      'areasAsArrayV2',
      'categoriesAsArrayV2',
    ]),
    categoryDataTables() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataTables: any[] = []

      // Add data table for each area
      this.areasAsArrayV2.forEach((area: IAreaV2) => {
        dataTables.push({
          columns: this.columns,
          rows: [],
          id: area.id,
          order: area.order,
          title: area.translation,
        })
      })

      // Add categories
      this.categoriesAsArrayV2.forEach((category: ICategoryV2) => {
        const dataTable = dataTables.find((x) => x.id === category.areaID)

        dataTable.rows.push({
          id: category.id,
          position: category.position,
          title: this.mixWB(category.translation),
          categoryTypes: category.getCategoryTypes().length.toString(),
          askForPlaster: category.options?.askForPlaster,
          askForWallpaper: category.options?.askForWallpaper,
          isSummaryCategory: !!category.connectedMaterialIDs.length,
          isActive: category.options?.isActive,
        })
      })

      return sortBy(dataTables, 'order')
    },
  },
  methods: {
    onAddCategoryClick(): void {
      this.showDialog = true
    },
    onEditCategoryClick(categoryID: string): void {
      this.$router.push({ name: 'CategoryEditV2', params: { categoryID } })
    },
  },
  components: {
    Page,
    Button,
    Dialog,
    DataTable,
    PageTop,
    Edit,
  },
})
