
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewCategoryTypeGroupDialog from '@/components/Modals/Dialogs/ResourcesV2/NewCategoryTypeGroupDialogV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICategoryTypeGroupV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeGroupV2'
import { IMaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CategoryTypeGroupsV2',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'materials',
          title: this.mixWB('W: Materialer'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewCategoryTypeGroupDialog: shallowRef(NewCategoryTypeGroupDialog),
    }
  },
  computed: {
    ...mapGetters([
      'materialsAsArrayV2',
      'categoryTypeGroupsAsArrayV2',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.categoryTypeGroupsAsArrayV2.forEach((categoryTypeGroup: ICategoryTypeGroupV2) => {
        rows.push({
          title: this.mixWB(categoryTypeGroup.translation),
          id: categoryTypeGroup.id,
          materials: categoryTypeGroup.materialIDs.reduce((prev: string, materialID: string) => {
            const material: IMaterialV2 = this.materialsAsArrayV2.find(
              (x: IMaterialV2) => x.id === materialID,
            )

            if (prev) {
              prev += ', '
            }

            prev += this.mixWB(material.translation)
            return prev
          }, ''),
        })
      })

      return rows
    },
  },
  methods: {
    onAddCategoryTypeGroupClick(): void {
      this.showDialog = true
    },
    onEditCategoryTypeGroupClick(categoryTypeGroupID: string): void {
      this.$router.push({ name: 'CategoryTypeGroupEditV2', params: { categoryTypeGroupID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    Dialog,
    DataTable,
    Edit,
  },
})
