
import { ILanguage, ITranslationEditItem } from '@/types/translations'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import { ObjPairString } from '@/types/general'
import { copyObject } from '@/globals/javascript/utils/helpers'

export default defineComponent({
  name: 'SingleTranslationForm',
  emits: ['submitted', 'deleted'],
  props: {
    translationToEdit: {
      type: Object as PropType<ITranslationEditItem>,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      translation: {
        key: '',
        translations: [],
      } as ITranslationEditItem,
      keyError: '',
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'translations',
    ]),
  },
  methods: {
    setTranslationOnLoad() {
      // Set translation
      this.translation = {
        key: '',
        translations: this.languages.map(
          (lang: ILanguage) => ({
            id: lang.locale,
            label: lang.countryCode,
            text: '',
          }),
        ),
      }

      // Set translation to edit
      if (this.translationToEdit) {
        this.translation = copyObject(this.translationToEdit)

        return
      }
    },
    onKeyInput() {
      this.keyError = ''
    },
    onKeyBlur() {
      this.onValidateFields(['key'])
    },
    onValidateFields(fieldNameArray: string[]) {
      let allGood = true

      if (fieldNameArray.length === 0 || fieldNameArray.includes('key')) {
        const editKeyValue = this.translationToEdit?.key
        const keyValue = this.translation.key

        // Check if key already exists
        if (
          keyValue
          && this.translations.hasOwnProperty(keyValue)
          && (!editKeyValue || (editKeyValue !== keyValue))
        ) {
          this.keyError = 'This key is already in use'
          allGood = false
        }

        // Check if key has required pattern
        if (keyValue && !keyValue.match(/^[A-Z0-9_]*$/)) {
          this.keyError = 'Key must follow pattern: LIKE_THIS'
          allGood = false
        }
      }

      return allGood
    },
    onDeleteClick() {
      const answer = window.confirm('Are you sure you want to delete this?')
      if (!answer) {
        return
      }

      const editKeyValue = this.translationToEdit?.key

      if (!editKeyValue) {
        return
      }

      this.languages.forEach((lang: ILanguage) => {
        this.$store.dispatch('removeTranslation', {
          locale: lang.locale,
          key: editKeyValue,
        })
      })

      this.$emit('deleted')
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      const passedValidation = this.onValidateFields([])

      if (!passedValidation) {
        return
      }

      this.isLoading = true

      // Add each translation to DB
      this.translation.translations.forEach((trans: ObjPairString) => {
        if (trans.text) {
          this.$store.dispatch('addTranslation', {
            locale: trans.id,
            key: this.translation.key,
            value: trans.text,
          })
        }
      })

      // Remove old key if any
      const editKeyValue = this.translationToEdit?.key

      if (editKeyValue && editKeyValue !== this.translation.key) {
        this.languages.forEach((lang: ILanguage) => {
          this.$store.dispatch('removeTranslation', {
            locale: lang.locale,
            key: editKeyValue,
          })
        })
      }

      this.$emit('submitted')
    },
  },
  components: {
    InputField,
    Button,
  },
  created() {
    this.setTranslationOnLoad()
  },
})
