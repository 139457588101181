
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import { ObjPairString } from '@/types/general'
import { FracionTypesV3 } from '@/types/resources-version-3'
import { orderBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

interface ISuggestion {
  id: string
  title: string
  score?: number
}

export default defineComponent({
  name: 'FractionSelectV3',
  props: {
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<FracionTypesV3>,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
    }
  },
  computed: {
    ...mapGetters([
      'fractionsAsArrayV3',
    ]),
    filteredFractions(): FractionV3[] {
      const type: FracionTypesV3 = ['clean', 'contaminated'].includes(this.type)
        ? this.type
        : FracionTypesV3.OTHER
      return this.fractionsAsArrayV3.filter((x: FractionV3) => x.types.includes(type))
    },
    selectedItems(): ObjPairString[] {
      if (!this.data) {
        return []
      }

      const item: FractionV3 = this.fractionsAsArrayV3.find((x: FractionV3) => x.id === this.data)

      return [
        {
          title: this.mixWB(item.translation),
        },
      ]
    },
    suggestions(): ISuggestion[] {
      const suggestions: ISuggestion[] = []
      const searchTerm = this.searchTerm.toLowerCase()

      // No search term
      if (!searchTerm) {
        this.filteredFractions.forEach((fraction: FractionV3) => {
          suggestions.push({
            id: fraction.id,
            title: this.mixWB(fraction.translation),
          })
        })
      }

      // With search term
      else {
        this.filteredFractions.forEach((fraction: FractionV3) => {
          const text = this.mixWB(fraction.translation).toLowerCase()

          // Exact match
          if (text === searchTerm) {
            suggestions.push({
              id: fraction.id,
              title: this.mixWB(fraction.translation),
              score: 100,
            })
          }
          // Partial match
          else if (text.includes(searchTerm)) {
            suggestions.push({
              id: fraction.id,
              title: this.mixWB(fraction.translation),
              score: Math.ceil((searchTerm.length / text.length) * 100),
            })
          }
        })
      }

      return orderBy(suggestions, ['score', 'title'], ['desc', 'asc'])
    },
  },
  methods: {
    onSearchTermInput(): void {
      this.showSuggestions = true
    },
    onRemoveItem(): void {
      this.$emit('update:data', '')
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion: ISuggestion): void {
      this.$emit('update:data', suggestion.id)
      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
})
