
import CategoryTypeGroupForm from '@/components/Forms/ResourcesV2/CategoryTypeGroupFormV2.vue'
import { defineComponent } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'

export default defineComponent({
  name: 'NewCategoryTypeGroupDialog',
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    CategoryTypeGroupForm,
  },
})
