
import { PropType, defineComponent } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import { mapGetters } from 'vuex'
import { copyObject, updateLog } from '@/globals/javascript/utils/helpers'
import Toggle from '@/components/Form/Toggle.vue'
import ChangeLogV3 from '@/components/ChangeLogV3.vue'
import {
  IPriceItem, IPricesLabsItem, IPricesProItem, UPricesItem,
} from '@/types/prices'

export default defineComponent({
  name: 'NewPriceDialog',
  props: {
    priceItemCategoryTitle: {
      type: String,
      required: false,
    },
    priceItemTitle: {
      type: String,
      required: false,
    },
    priceItemID: {
      type: String,
      required: false,
    },
    priceCategoryKey: {
      type: String as PropType<Exclude<keyof UPricesItem, 'id' | 'translation'>>,
      required: false,
    },
  },
  data() {
    return {
      areLogsShowing: false,
      isLoading: false,

      // Item values
      sampler: null as number | null,
      samplerInitialValue: null as number | null,
      samplerItem: null as IPriceItem | null,
      samplerPlus: null as number | null,
      samplerPlusInitialValue: null as number | null,
      samplerPlusItem: null as IPriceItem | null,
      eurofins: null as number | null,
      eurofinsInitialValue: null as number | null,
      eurofinsItem: null as IPriceItem | null,
      isMultiplier: false,
    }
  },
  computed: {
    ...mapGetters([
      'pricesProAsArray',
      'pricesLabsAsArray',
    ]),
    unit() :string {
      return this.isMultiplier ? this.mixWB('W: %') : this.mixWB('W: DKK')
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
    updateValue(priceItem: IPriceItem | undefined, value: number) {
      if (priceItem === undefined) {
        return
      }
      if (priceItem.type === 'multiplier') {
        priceItem.value = value / 100
        return
      }
      priceItem.value = value
      return
    },
    getValue(priceItem: IPriceItem) {
      if (priceItem.type === 'multiplier') {
        return priceItem.value * 100
      }
      return priceItem.value
    },
    getPriceItem(pricesItem: UPricesItem): IPriceItem | undefined {
      if (!this.priceCategoryKey) {
        return undefined
      }

      const categoryItem = pricesItem[this.priceCategoryKey]
      if (!categoryItem) {
        return undefined
      }

      const item = categoryItem[this.priceItemID as keyof typeof categoryItem]

      return item
    },
    setDataOnLoad() {
      const pricesArray = [...this.pricesProAsArray, ...this.pricesLabsAsArray] as UPricesItem[]
      for (let i = 0; i < pricesArray.length; i += 1) {
        const pricesItem = pricesArray[i]
        const item = this.getPriceItem(pricesItem)
        if (item?.type === 'multiplier') {
          this.isMultiplier = true
        }

        if (item && pricesItem.id === 'sampler') {
          this.sampler = this.getValue(item)
          this.samplerInitialValue = this.sampler
          this.samplerItem = item
        }
        if (item && pricesItem.id === 'samplerPlus') {
          this.samplerPlus = this.getValue(item)
          this.samplerPlusInitialValue = this.samplerPlus
          this.samplerPlusItem = item
        }
        if (item && pricesItem.id === 'eurofins') {
          this.eurofins = this.getValue(item)
          this.eurofinsInitialValue = this.eurofins
          this.eurofinsItem = item
        }
      }
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true
      const pricesProCopy = copyObject(this.pricesProAsArray) as IPricesProItem[]
      const pricesLabsCopy = copyObject(this.pricesLabsAsArray) as IPricesLabsItem[]

      let hasProChanges = false
      let hasLabChanges = false

      if (this.sampler !== null && this.samplerInitialValue !== this.sampler) {
        hasProChanges = true
        const priceItem = pricesProCopy.find((p) => p.id === 'sampler')
        const newItem = this.getPriceItem(priceItem as IPricesProItem)
        const oldItem = copyObject(newItem)
        this.updateValue(newItem, this.sampler)
        // Add new log
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      }
      if (this.samplerPlus !== null && this.samplerPlusInitialValue !== this.samplerPlus) {
        hasProChanges = true
        const priceItem = pricesProCopy.find((p) => p.id === 'samplerPlus')
        const newItem = this.getPriceItem(priceItem as IPricesProItem)
        const oldItem = copyObject(newItem)
        this.updateValue(newItem, this.samplerPlus)
        // Add new log
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      }
      if (this.eurofins !== null && this.eurofinsInitialValue !== this.eurofins) {
        hasLabChanges = true
        const priceItem = pricesLabsCopy.find((p) => p.id === 'eurofins')
        const newItem = this.getPriceItem(priceItem as IPricesProItem)
        const oldItem = copyObject(newItem)
        this.updateValue(newItem, this.eurofins)
        // Add new log
        updateLog({
          newItem,
          oldItem,
          isNew: false,
        })
      }

      // Save type
      if (hasProChanges) {
        this.$store.dispatch('setPrices', {
          doc: '-prices-pro-',
          data: pricesProCopy,
        })
      }
      if (hasLabChanges) {
        this.$store.dispatch('setPrices', {
          doc: '-prices-labs-',
          data: pricesLabsCopy,
        })
      }

      setTimeout(() => {
        this.isLoading = false
        this.$emit('close-dialog')
      }, 500)
    },
  },
  components: {
    DialogTitle,
    Toggle,
    InputField,
    Button,
    ChangeLogV3,
  },
  mounted() {
    this.setDataOnLoad()
  },

})
