
import { orderBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'

interface ISelectedItem {
  id: string
  title: string
}

interface ISuggestion {
  id: string
  title: string
  score?: number
}

type IItem = any

export default defineComponent({
  name: 'SearchSelect',
  emits: ['update:data'],
  props: {
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    data: {
      type: [String, Array],
      required: true,
    },
    itemList: {
      type: Array as PropType<IItem[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    maxResults: {
      type: Number,
      required: false,
      default: 1,
    },
    errorText: {
      type: String,
      required: false,
      default: '',
    },
    fieldName: {
      type: String,
      required: false,
      default: 'translation',
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
    }
  },
  computed: {
    selectedItems(): ISelectedItem[] {
      // Data as string
      if (typeof this.data === 'string') {
        if (!this.data) {
          return []
        }

        const item = this.itemList.find((x: IItem) => x.id === this.data)

        if (!item) {
          return []
        }

        return [
          {
            id: item.id,
            title: this.mixWB(item[this.fieldName]),
          },
        ]
      }

      // Data as array
      if (!(this.data as []).length) {
        return []
      }

      return this.itemList.reduce((prev: ISelectedItem[], item: IItem) => {
        if (this.data.includes(item.id)) {
          prev.push(
            {
              id: item.id,
              title: this.mixWB(item[this.fieldName]),
            },
          )
        }
        return prev
      }, [])
    },
    suggestions(): ISuggestion[] {
      // No search term
      if (!this.searchTerm) {
        const suggestions: ISuggestion[] = []
        this.itemList.forEach((item: IItem) => {
          // Don't show already selected
          if (typeof this.data === 'object' && this.data.length) {
            if (this.data.includes(item.id)) {
              return
            }
          }
          suggestions.push({
            id: item.id,
            title: this.mixWB(item[this.fieldName]),
          })
        })

        return orderBy(suggestions, ['title'], ['asc'])
      }

      // With search term
      const suggestions: ISuggestion[] = this.itemList.reduce((
        prev: ISuggestion[],
        item: IItem,
      ) => {
        const searchTerm = this.searchTerm.toLowerCase()
        const text = this.mixWB(item[this.fieldName]).toLowerCase()

        // Don't show already selected
        if (typeof this.data === 'object') {
          if (this.data.includes(item.id)) {
            return prev
          }
        }

        // Exact match
        if (text === searchTerm) {
          prev.push({
            id: item.id,
            title: this.mixWB(item[this.fieldName]),
            score: 100,
          })
        }

        // Partial match
        else if (text.includes(searchTerm)) {
          prev.push({
            id: item.id,
            title: this.mixWB(item[this.fieldName]),
            score: Math.ceil((searchTerm.length / text.length) * 100),
          })
        }
        return prev
      }, [])

      return orderBy(suggestions, ['score'], ['desc'])
    },
  },
  methods: {
    onSearchTermInput(): void {
      this.showSuggestions = true
    },
    onRemoveItem(id: string): void {
      // Data as string
      if (typeof this.data === 'string') {
        this.$emit('update:data', '')
        return
      }

      // Data as array
      const index = this.data.findIndex((x) => x === id)
      const dataCopy = [...this.data]
      dataCopy.splice(index, 1)
      this.$emit('update:data', dataCopy)
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion: ISuggestion): void {
      // Data as string
      if (typeof this.data === 'string') {
        this.$emit('update:data', suggestion.id)
      }
      // Data as array
      else {
        this.$emit('update:data', this.data.concat(suggestion.id))
      }

      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
})
