
import Button from '@/components/Form/Button.vue'
import Divider from '@/components/Form/Divider.vue'
import InputField from '@/components/Form/InputField.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import { CategoryV3 } from '@/globals/javascript/models/resources-version-3/CategoryV3'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CategoryFormV3',
  props: {
    categoryToEdit: {
      type: Object as PropType<CategoryV3>,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      category: {} as CategoryV3,
      translationError: '',
      positionError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextCategoryIDV3',
      'areasAsArrayV3',
      'materialsAsArrayV3',
      'typesAsArrayV3',
      'typeGroupsAsArrayV3',
      'categoriesAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.categoryToEdit
    },
  },
  methods: {
    setDataOnLoad() {
      // Edit
      if (this.categoryToEdit) {
        this.category = new CategoryV3(copyObject(this.categoryToEdit))
        return
      }

      // New
      this.category = new CategoryV3({
        id: this.nextCategoryIDV3,
        position: this.categoriesAsArrayV3.length + 1,
      })
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.category.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(this.category.translation,
          this.categoriesAsArrayV3,
          this.category.id)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Position
      if (allGood && this.category.position < 1) {
        this.positionError = this.mixWB('W: Skal være 1 eller over')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      let newCategories: CategoryV3[] = []
      this.categoriesAsArrayV3.forEach((category: CategoryV3) => {
        if (category.id === this.category.id) {
          return
        }

        // Check to update position
        const newCategory = new CategoryV3(copyObject(category))
        if (this.isNew && newCategory.position >= this.category.position) {
          newCategory.position += 1
        }
        newCategories.push(newCategory)
      })

      newCategories = sortBy(newCategories, ['position'])
      newCategories.forEach((category, index) => {
        if (index + 1 >= this.category.position) {
          category.position = index + 2
        }
        else {
          category.position = index + 1
        }
      })

      // Add new (or edited) item
      newCategories.push(this.category)

      // Save
      this.$store.dispatch('setResourceV3', {
        doc: '-categories-',
        data: sortBy(newCategories, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    TranslationSelect,
    InputField,
    SearchSelect,
    Divider,
    Toggle,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
})
