
import GroupQuestionFormV3 from '@/components/Forms/ResourcesV3/GroupQuestionFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'

export default defineComponent({
  name: 'NewGroupQuestionDialogV3',
  props: {
    groupQuestionToEdit: {
      type: Object as PropType<GroupQuestionV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    GroupQuestionFormV3,
  },

})
