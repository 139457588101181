
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewMaterialDialog from '@/components/Modals/Dialogs/ResourcesV2/NewMaterialDialogV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { IMaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import { ITypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'MaterialsV2',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'types',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'co2',
          title: this.mixWB('W: Co2'),
          canSort: true,
        },
        {
          key: 'density',
          title: this.mixWB('W: Densitet'),
          canSort: true,
        },
        {
          key: 'isMetal',
          title: this.mixWB('W: Er metal?'),
          canSort: true,
        },
        {
          key: 'excludeFromPCBScreening',
          title: this.mixWB('W: Undladt fra PCB screening?'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewMaterialDialog: shallowRef(NewMaterialDialog),
    }
  },
  computed: {
    ...mapGetters([
      'materialsAsArrayV2',
      'typesAsArrayV2',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.materialsAsArrayV2.forEach((material: IMaterialV2) => {
        const user = material.getUser()

        rows.push({
          id: material.id,
          title: this.mixWB(material.translation),
          types: this.typesAsArrayV2.filter(
            (x: ITypeV2) => x.materialIDs.includes(material.id),
          ).length.toString(),
          co2: `${ material.metaData.co2.value } ${ this.mixWB('TONS_PER_M3') }`,
          density: `${ material.metaData.density.value } ${ this.mixWB('TONS_PER_M3') }`,
          isMetal: material.options.isMetal,
          excludeFromPCBScreening: material.options.excludeFromPCBScreening,
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onAddMaterialClick(): void {
      this.showDialog = true
    },
    onEditMaterialClick(materialID: string): void {
      this.$router.push({ name: 'MaterialEditV2', params: { materialID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Dialog,
    Edit,
  },

})
