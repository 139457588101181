
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import TagForm from '@/components/Forms/ResourcesV2/TagFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { IQuestionV2 } from '@/globals/javascript/models/resources-version-2/QuestionV2'
import { ITagV2 } from '@/globals/javascript/models/resources-version-2/TagV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TagEditV2',
  data() {
    return {
      questionColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'tagsAsArrayV2',
      'questionsAsArrayV2',
    ]),
    tagToEdit(): ITagV2 {
      const { tagID } = this.$route.params

      return this.tagsAsArrayV2.find((x: ITagV2) => x.id === tagID)
    },
    questionRows(): Row[] {
      return this.questionsAsArrayV2.reduce((prev: Row[], question: IQuestionV2) => {
        const isUsingTag = question.tagIDs.includes(this.tagToEdit.id)

        if (isUsingTag) {
          prev.push({
            id: question.id,
            title: this.mixWB(question.translation),
          })
        }

        return prev
      }, [])
    },
  },
  methods: {
    onEditQuestionClick(questionID: string): void {
      this.$router.push({ name: 'QuestionEditV2', params: { questionID } })
    },
  },
  components: {
    Page,
    PageTop,
    TagForm,
    DataTable,
    Edit,
  },
})
