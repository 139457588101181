
import CategoryFormV3 from '@/components/Forms/ResourcesV3/CategoryFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'

export default defineComponent({
  name: 'NewCategoryDialogV3',
  props: {
    categoryToEdit: {
      type: Object as PropType<FractionV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    CategoryFormV3,
  },

})
