
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { CoatingTypeV3 } from '@/globals/javascript/models/resources-version-3/CoatingTypeV3'
import { CoatingV3 } from '@/globals/javascript/models/resources-version-3/CoatingV3'
import { IColorV3, ISampleTypeV3 } from '@/types/resources-version-3'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CoatingsV3',
  data() {
    return {
      coatingColumns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'samples',
          title: this.mixWB('W: Prøver'),
          canSort: true,
        },
        {
          key: 'contains',
          title: this.mixWB('W: Indeholder'),
          canSort: true,
        },
        {
          key: 'hasColorSelect',
          title: this.mixWB('W: Spørg om farve?'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
      coatingTypeColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
      ],
      colorColumns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'coatingsAsArrayV3',
      'coatingTypesAsArrayV3',
      'colorsAsArrayV3',
      'currentUser',
      'sampleTypesAsArrayV3',
    ]),
    coatingRows(): Row[] {
      const rows: Row[] = []

      this.coatingsAsArrayV3.forEach((coating: CoatingV3) => {
        const sampleNames: string[] = coating.sampleIDs.map((sampleID: string) => {
          const sampleType = this.sampleTypesAsArrayV3.find(
            (x: ISampleTypeV3) => x.id === sampleID,
          )

          return this.mixWB(sampleType.translation)
        })
        const contains: string[] = coating.coatingTypeIDs.map((coatingTypeID: string) => {
          const coatingType = this.coatingTypesAsArrayV3.find(
            (x: CoatingTypeV3) => x.id === coatingTypeID,
          )
          return this.mixWB(coatingType.translation)
        })

        rows.push({
          position: coating.position,
          title: this.mixWB(coating.translation),
          id: coating.id,
          samples: sampleNames.length ? sampleNames.join(', ') : '-',
          contains: contains.length ? contains.join(', ') : '-',
          hasColorSelect: coating.options.hasColorSelect,
          isActive: coating.options.isActive,
        })
      })

      return rows
    },
    coatingTypeRows(): Row[] {
      const rows: Row[] = []

      this.coatingTypesAsArrayV3.forEach((coating: CoatingTypeV3) => {
        rows.push({
          title: this.mixWB(coating.translation),
          id: coating.id,
        })
      })

      return rows
    },
    colorRows(): Row[] {
      const rows: Row[] = []

      this.colorsAsArrayV3.forEach((color: IColorV3) => {
        rows.push({
          position: color.position,
          title: this.mixWB(color.translation),
          id: color.id,
          isActive: color.options.isActive,
        })
      })

      return rows
    },
  },
  methods: {
    onAddLye() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('addLye')
    },
  },
  components: {
    Page, PageTop, Button, DataTable,
  },
})
