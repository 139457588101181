
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { CustomRoomV2 } from '@/globals/javascript/models/resources-version-2/CustomRoomV2'
import { IAccount, TUser } from '@/types'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CustomPlaces',
  data() {
    return {
      selectedIndexes: [] as number[],
      columns: [
        {
          key: 'isSelected',
          title: '',
          canSort: false,
        },
        {
          key: 'index',
          title: this.mixWB('W: Nummer'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'category',
          title: this.mixWB('W: Kategori'),
          canSort: true,
        },
        {
          key: 'screeningID',
          title: this.mixWB('W: Milva Online'),
          canSort: true,
        },
        {
          key: 'account',
          title: this.mixWB('W: Konto'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'environment',
      'customRoomsV2',
      'categoriesAsArrayV2',
      'accounts',
      'users',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.customRoomsV2.forEach((customRoom: CustomRoomV2, index: number) => {
        const category = this.categoriesAsArrayV2.find(
          (x: ICategoryV2) => x.id === customRoom.categoryID,
        )
        const account = this.accounts.find((x: IAccount) => x.id === customRoom.accountID)
        const user = this.users.find((x: TUser) => x.id === customRoom.userID)
        rows.push({
          index,
          title: this.mixWB(customRoom.name),
          category: category ? this.mixWB(category.translation) : '-',
          screeningID: customRoom.screeningID,
          account: account ? account.name : '-',
          user: user ? user.fullName : '-',
        })
      })

      return rows
    },
  },
  methods: {
    onDeletePlaces() {
      this.$store.dispatch('deleteCustomRoomsV2', this.selectedIndexes)
      this.selectedIndexes = []
    },
    onCheckboxClick(index: string) {
      const existingIndex = this.selectedIndexes.indexOf(Number(index))

      if (existingIndex > -1) {
        this.selectedIndexes.splice(existingIndex, 1)
        return
      }

      this.selectedIndexes.push(Number(index))
    },
  },
  components: {
    Page, PageTop, DataTable, Button,
  },
})
