
import { defineComponent, PropType } from 'vue'
import InputField from '@/components/Form/InputField.vue'
import { mapGetters } from 'vuex'
import Divider from '@/components/Form/Divider.vue'
import Button from '@/components/Form/Button.vue'
import { copyObject } from '@/globals/javascript/utils/helpers'
import { QuestionV3 } from '@/globals/javascript/models/resources-version-3/QuestionV3'
import { sortBy } from 'lodash-es'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'

export default defineComponent({
  name: 'QuestionFormV3',
  props: {
    questionToEdit: {
      type: Object as PropType<QuestionV3>,
      required: false,
    },
  },
  data() {
    return {
      question: {} as QuestionV3,
      isLoading: false,
      questionTypeError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextQuestionIDV3',
      'questionsAsArrayV3',
      'sampleTypesForSearchSelectAsArrayV3',
      'tagsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.questionToEdit
    },
    disableYearField(): boolean {
      return !!(this.questionToEdit && this.questionToEdit.translation)
    },
    disableTranslationField(): boolean {
      return !!(this.questionToEdit && this.questionToEdit.year)
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.questionToEdit) {
        this.question = new QuestionV3(copyObject(this.questionToEdit))
        return
      }

      // New
      this.question = new QuestionV3({ id: this.nextQuestionIDV3 })
    },
    onYearInput(): void {
      this.question.translation = ''
      this.questionTypeError = ''
    },
    onTranslationSelect() {
      this.question.year = 0
      this.questionTypeError = ''
    },
    onValidation(): boolean {
      let allGood = true

      // Type of question
      if (allGood && !this.question.year && !this.question.translation) {
        this.questionTypeError = this.isNew
          ? this.mixWB('W: Udfyld én af disse')
          : this.mixWB('W: Denne mangler')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newQuestions: QuestionV3[] = []
      this.questionsAsArrayV3.forEach((question: QuestionV3) => {
        if (question.id === this.question.id) {
          return
        }

        newQuestions.push(new QuestionV3(copyObject(question)))
      })

      // Add new (or edited) type
      newQuestions.push(this.question)

      // Save type
      this.$store.dispatch('setResourceV3', {
        doc: '-questions-',
        data: sortBy(newQuestions, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    SearchSelect,
    Divider,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
})
