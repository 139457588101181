
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import NewTypeGroupDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewTypeGroupDialogV3.vue'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import Dialog from '@/components/Modals/Dialog.vue'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import { TypeGroupV3 } from '@/globals/javascript/models/resources-version-3/TypeGroupV3'
import Edit from '@/components/DataTable/Edit.vue'
import { CategoryV3 } from '@/globals/javascript/models/resources-version-3/CategoryV3'

export default defineComponent({
  name: 'TypeGroupsV3',
  data() {
    return {
      columns: [
        {
          key: 'internalLabel',
          title: this.mixWB('W: Intern label'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'noOfTypes',
          title: this.mixWB('W: Antal produkter'),
          canSort: true,
        },
        {
          key: 'noOfPlacesUsed',
          title: this.mixWB('W: Steder brugt'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],

      // Dialog
      showDialog: false,
      dialogProps: {
        typeGroupToEdit: null,
      },
      NewTypeGroupDialogV3: shallowRef(NewTypeGroupDialogV3),
    }
  },
  computed: {
    ...mapGetters([
      'typeGroupsAsArrayV3',
      'categoriesAsArrayV3',
      'currentUser',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.typeGroupsAsArrayV3.forEach((typeGroup: TypeGroupV3) => {
        const categories = this.categoriesAsArrayV3.filter(
          (x: CategoryV3) => x.suggestedTypeGroupIDs.includes(typeGroup.id),
        )

        rows.push({
          internalLabel: typeGroup.internalLabel,
          title: this.mixWB(typeGroup.translation),
          id: typeGroup.id,
          noOfTypes: typeGroup.typeIDs.length.toString(),
          noOfPlacesUsed: categories.map((x: CategoryV3) => this.mixWB(x.translation)).join(', '),
          isActive: typeGroup.options.isActive,
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3TypeGroups')
    },
    onAddTypeGroupClick() {
      this.showDialog = true
      this.dialogProps.typeGroupToEdit = null
    },
    onEditTypeGroupClick(typeGroupID: string): void {
      const typeGroup = this.typeGroupsAsArrayV3.find((x: TypeGroupV3) => x.id === typeGroupID)
      this.dialogProps.typeGroupToEdit = typeGroup
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    Dialog,
    DataTable,
    Edit,
  },
})
