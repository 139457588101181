
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICategoryTypeV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import NewCategoryTypeDialog from '@/components/Modals/Dialogs/ResourcesV2/NewCategoryTypeDialogV2.vue'
import Dialog from '@/components/Modals/Dialog.vue'

export default defineComponent({
  name: 'CategoryTypesV2',
  data() {
    return {
      columns: [
        {
          key: 'category',
          title: this.mixWB('W: Kategori'),
          canSort: true,
        },
        {
          key: 'type',
          title: this.mixWB('W: Type'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'askForCoating',
          title: this.mixWB('W: Check overflade?'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewCategoryTypeDialog: shallowRef(NewCategoryTypeDialog),
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypesAsArrayV2',
    ]),
    rows(): Row[] {
      return this.categoryTypesAsArrayV2.reduce(
        (prev: Row[], categoryType: ICategoryTypeV2) => {
          const user = categoryType.getUser()

          prev.push({
            id: categoryType.id,
            category: categoryType.getCategoryTitle(),
            categoryID: categoryType.categoryID,
            type: categoryType.getTypeTitle(),
            typeID: categoryType.typeID,
            title: categoryType.getTitle(),
            askForCoating: categoryType.options.askForCoating,
            isActive: categoryType.options.isActive,
            user: user ? user.fullName : '',
          })
          return prev
        }, [],
      )
    },
  },
  methods: {
    onAddCategoryTypeClick() {
      this.showDialog = true
    },
    onEditCategoryTypeClick(categoryTypeID: string) {
      this.$router.push({ name: 'CategoryTypeEditV2', params: { categoryTypeID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },
})
