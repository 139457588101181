
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewFractionDialogVue from '@/components/Modals/Dialogs/ResourcesV2/NewFractionDialogV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { FracionTypesV2, IFractionV2 } from '@/globals/javascript/models/resources-version-2/FractionV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'FractionsV2',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'clean',
          title: this.mixWB('CLEAN'),
          canSort: true,
        },
        {
          key: 'contaminated',
          title: this.mixWB('CONTAMINATED_2'),
          canSort: true,
        },
        {
          key: 'other',
          title: this.mixWB('OTHER'),
          canSort: true,
        },
        {
          key: 'avgPrice',
          title: this.mixWB('W: Gen. pris'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewFractionDialog: shallowRef(NewFractionDialogVue),
    }
  },
  computed: {
    ...mapGetters([
      'fractionsAsArrayV2',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.fractionsAsArrayV2.forEach((fraction: IFractionV2) => {
        const user = fraction.getUser()

        rows.push({
          title: this.mixWB(fraction.translation),
          id: fraction.id,
          clean: fraction.types.includes(FracionTypesV2.CLEAN),
          contaminated: fraction.types.includes(FracionTypesV2.CONTAMINATED),
          other: fraction.types.includes(FracionTypesV2.OTHER),
          avgPrice: fraction.avgPrice ? `${ fraction.avgPrice.toString() } ${ this.mixWB('W: DKK / ton') }` : '-',
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onAddFractionClick(): void {
      this.showDialog = true
    },
    onEditFractionClick(fractionID: string): void {
      this.$router.push({ name: 'FractionEditV2', params: { fractionID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Dialog,
    Edit,
  },

})
