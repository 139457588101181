
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { formatEWCCode } from '@/globals/javascript/utils/helpers'
import {
  IAreaV2, ICoatingTypeV2, IEWCCodeV2, IWDGV2,
} from '@/types/resources-version-2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Other',
  data() {
    return {
      areaColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'order',
          title: this.mixWB('W: Rækkefølge'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
      ],
      wdgColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
      ],
      ewcColumns: [
        {
          key: 'code',
          title: this.mixWB('W: Kode'),
          canSort: true,
        },
        {
          key: 'description',
          title: this.mixWB('W: Beskrivelse'),
        },
      ],
      coatingTypesColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Type'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'areasAsArrayV2',
      'workDescriptionGroupsAsArrayV2',
      'ewcCodesAsArrayV2',
      'coatingTypesAsArrayV2',
    ]),
    areaRows(): Row[] {
      const rows: Row[] = []
      this.areasAsArrayV2.forEach((area: IAreaV2) => {
        rows.push({
          id: area.id,
          order: area.order.toString(),
          title: this.mixWB(area.translation),
        })
      })
      return rows
    },
    wdgRows(): Row[] {
      const rows: Row[] = []
      this.workDescriptionGroupsAsArrayV2.forEach((wdg: IWDGV2) => {
        rows.push({
          id: wdg.id,
          title: this.mixWB(wdg.translation),
        })
      })
      return rows
    },
    ewcRows(): Row[] {
      const rows: Row[] = []
      this.ewcCodesAsArrayV2.forEach((ewcCode: IEWCCodeV2) => {
        rows.push({
          id: ewcCode.id,
          code: formatEWCCode(ewcCode.id),
          description: this.mixWB(ewcCode.translation),
        })
      })
      return rows
    },
    coatingTypeRows(): Row[] {
      const rows: Row[] = []

      this.coatingTypesAsArrayV2.forEach((coating: ICoatingTypeV2) => {
        rows.push({
          id: coating.id,
          title: this.mixWB(coating.translation),
        })
      })

      return rows
    },
  },
  components: {
    Page,
    PageTop,
    DataTable,
  },
})

