
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import QuestionForm from '@/components/Forms/ResourcesV2/QuestionFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { IQuestionV2 } from '@/globals/javascript/models/resources-version-2/QuestionV2'
import { ITypeV2, ITypeQuestionV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'QuestionEditV2',
  data() {
    return {
      typeColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'questionsAsArrayV2',
      'typesAsArrayV2',
    ]),
    questionToEdit(): IQuestionV2 {
      const { questionID } = this.$route.params

      return this.questionsAsArrayV2.find((x: IQuestionV2) => x.id === questionID)
    },
    typeRows(): Row[] {
      return this.typesAsArrayV2.reduce((prev: Row[], type: ITypeV2) => {
        const isUsingQuestion = type.questions.find(
          (x: ITypeQuestionV2) => x.questionID === this.questionToEdit.id,
        )
        if (isUsingQuestion) {
          prev.push({
            id: type.id,
            title: this.mixWB(type.translation),
          })
        }

        return prev
      }, [])
    },
  },
  methods: {
    onEditTypeClick(typeID: string): void {
      this.$router.push({ name: 'TypeEditV2', params: { typeID } })
    },
  },
  components: {
    Page,
    PageTop,
    QuestionForm,
    DataTable,
    Edit,
  },

})

