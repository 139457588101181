
import { IMaterialV2, IMaterialFractionV2, MaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import { copyObject, doesListAlreadyIncludeItem, updateLog } from '@/globals/javascript/utils/helpers'
import EWCSelectV2 from '@/components/Form/EWCSelectV2.vue'
import FractionSelectV2 from '@/components/Form/FractionSelectV2.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import Divider from '@/components/Form/Divider.vue'

export default defineComponent({
  name: 'MaterialForm',
  props: {
    materialToEdit: {
      type: Object as PropType<IMaterialV2>,
      required: false,
    },
  },
  data() {
    return {
      material: {} as IMaterialV2,
      isLoading: false,
      translationError: '',
      co2Error: '',
      densityError: '',
      fractionsError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextMaterialIDV2',
      'materialsAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.materialToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.materialToEdit) {
        this.material = new MaterialV2(copyObject(this.materialToEdit))
        return
      }

      // New
      this.material = MaterialV2.newInstance(this.nextMaterialIDV2)
    },
    onKeyInput() {
      this.translationError = ''
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.material.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && this.isNew
        && doesListAlreadyIncludeItem(this.material.translation, this.materialsAsArrayV2)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Meta data
      if (!this.material.metaData.density.value) {
        this.densityError = this.mixWB('W: Udfyld denne')
        allGood = false
      }

      // Fractions + source
      let isFractionsAllGood = true
      Object.keys(this.material.fractions.fractions).forEach((fractionKey: string) => {
        const fraction: IMaterialFractionV2 = this.material.fractions.fractions[fractionKey]
        if (!fraction.ewcID || !fraction.fractionID) {
          isFractionsAllGood = false
        }
      })

      if (!isFractionsAllGood || !this.material.fractions.source) {
        this.fractionsError = this.mixWB('W: Udfyld alle felter')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newMaterials: IMaterialV2[] = []
      this.materialsAsArrayV2.forEach((material: IMaterialV2) => {
        if (material.id === this.material.id) {
          return
        }

        newMaterials.push({ ...material })
      })

      // Add new log
      updateLog({
        newItem: this.material,
        oldItem: this.materialToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) type
      newMaterials.push({ ...this.material })

      // Save type
      this.$store.dispatch('setResourceV2', {
        doc: '-materials-',
        data: newMaterials,
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    Button,
    TranslationSelect,
    EWCSelectV2,
    FractionSelectV2,
    Toggle,
    Divider,
  },
  created() {
    this.setDataOnLoad()
  },
})
