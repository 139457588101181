
import { defineComponent, PropType } from 'vue'
import InputField from '@/components/Form/InputField.vue'
import { IQuestionV2, QuestionV2 } from '@/globals/javascript/models/resources-version-2/QuestionV2'
import { mapGetters } from 'vuex'
import Divider from '@/components/Form/Divider.vue'
import Button from '@/components/Form/Button.vue'
import { copyObject } from '@/globals/javascript/utils/helpers'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'

export default defineComponent({
  name: 'QuestionForm',
  props: {
    questionToEdit: {
      type: Object as PropType<IQuestionV2>,
      required: false,
    },
  },
  data() {
    return {
      question: {} as IQuestionV2,
      isLoading: false,
      questionTypeError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextQuestionIDV2',
      'questionsAsArrayV2',
      'sampleTypesAsArrayV2',
      'tagsAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.questionToEdit
    },
    disableYearField(): boolean {
      return !!(this.questionToEdit && this.questionToEdit.translation)
    },
    disableTranslationField(): boolean {
      return !!(this.questionToEdit && this.questionToEdit.year)
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.questionToEdit) {
        this.question = new QuestionV2(copyObject(this.questionToEdit))
        return
      }

      // New
      this.question = QuestionV2.newInstance(this.nextQuestionIDV2)
    },
    onYearInput(): void {
      this.question.translation = ''
      this.questionTypeError = ''
    },
    onTranslationSelect() {
      this.question.year = 0
      this.questionTypeError = ''
    },
    onValidation(): boolean {
      let allGood = true

      // Type of question
      if (allGood && !this.question.year && !this.question.translation) {
        this.questionTypeError = this.isNew
          ? this.mixWB('W: Udfyld én af disse')
          : this.mixWB('W: Denne mangler')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newQuestions: IQuestionV2[] = []
      this.questionsAsArrayV2.forEach((question: IQuestionV2) => {
        if (question.id === this.question.id) {
          return
        }

        newQuestions.push({ ...question })
      })

      // Add new (or edited) type
      newQuestions.push({ ...this.question })

      // Save type
      this.$store.dispatch('setResourceV2', {
        doc: '-questions-',
        data: newQuestions,
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    SearchSelect,
    Divider,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
})
