
import { CategoryTypeGroupV2, ICategoryTypeGroupV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeGroupV2'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import InputField from '@/components/Form/InputField.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import Button from '@/components/Form/Button.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import { sortBy } from 'lodash-es'

export default defineComponent({
  name: 'CategoryTypeGroupForm',
  props: {
    categoryTypeGroupToEdit: {
      type: Object as PropType<ICategoryTypeGroupV2>,
      required: false,
    },
  },
  data() {
    return {
      categoryTypeGroup: {} as ICategoryTypeGroupV2,
      isLoading: false,
      translationError: '',
      materialError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextCategoryTypeGroupIDV2',
      'categoryTypeGroupsAsArrayV2',
      'materialsAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.categoryTypeGroupToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.categoryTypeGroupToEdit) {
        this.categoryTypeGroup = new CategoryTypeGroupV2(copyObject(this.categoryTypeGroupToEdit))
        return
      }

      // New
      this.categoryTypeGroup = CategoryTypeGroupV2.newInstance(this.nextCategoryTypeGroupIDV2)
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.categoryTypeGroup.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && this.isNew
        && doesListAlreadyIncludeItem(
          this.categoryTypeGroup.translation,
          this.categoryTypeGroupsAsArrayV2,
        )
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Materials
      if (!this.categoryTypeGroup.materialIDs.length) {
        this.materialError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newCategoryTypeGroup: ICategoryTypeGroupV2[] = []
      this.categoryTypeGroupsAsArrayV2.forEach((categoryTypeGroup: ICategoryTypeGroupV2) => {
        if (categoryTypeGroup.id === this.categoryTypeGroup.id) {
          return
        }

        newCategoryTypeGroup.push({ ...categoryTypeGroup })
      })

      // Add new (or edited) categoryTypeGroup
      newCategoryTypeGroup.push({ ...this.categoryTypeGroup })

      // Save categoryTypeGroup
      this.$store.dispatch('setResourceV2', {
        doc: '-category-type-groups-',
        data: sortBy(newCategoryTypeGroup, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    Button,
    SearchSelect,
  },
  created() {
    this.setDataOnLoad()
  },
})
