
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import NewMaterialDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewMaterialDialogV3.vue'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import Dialog from '@/components/Modals/Dialog.vue'
import Edit from '@/components/DataTable/Edit.vue'

export default defineComponent({
  name: 'MaterialsV3',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'types',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'co2',
          title: this.mixWB('W: Co2'),
          canSort: true,
        },
        {
          key: 'density',
          title: this.mixWB('W: Densitet'),
          canSort: true,
        },
        {
          key: 'isMetal',
          title: this.mixWB('W: Er metal?'),
          canSort: true,
        },
        {
          key: 'excludeFromPCBScreening',
          title: this.mixWB('W: Undladt fra PCB screening?'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      dialogProps: {
        materialToEdit: null,
      },
      NewMaterialDialogV3: shallowRef(NewMaterialDialogV3),
    }
  },
  computed: {
    ...mapGetters([
      'materialsAsArrayV3',
      'currentUser',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.materialsAsArrayV3.forEach((material: MaterialV3) => {
        const user = material.getUser()
        const types = material.getTypes()

        rows.push({
          id: material.id,
          title: this.mixWB(material.translation),
          types: types.length,
          co2: `${ material.metaData.co2.value } ${ this.mixWB('TONS_PER_M3') }`,
          density: `${ material.metaData.density.value } ${ this.mixWB('TONS_PER_M3') }`,
          isMetal: material.options.isMetal,
          excludeFromPCBScreening: material.options.excludeFromPCBScreening,
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Materials')
    },
    onAddMaterialClick() {
      this.showDialog = true
      this.dialogProps.materialToEdit = null
    },
    onEditMaterialClick(materialID: string): void {
      const material = this.materialsAsArrayV3.find((x: MaterialV3) => x.id === materialID)
      this.dialogProps.materialToEdit = material
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    Dialog,
    DataTable,
    Edit,
  },
})
