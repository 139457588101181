
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'SyncToDev',
  computed: {
    ...mapGetters([
      'environment',
      'areHubAccountsSyncing',
      'areAllV3ResourcesSyncing',
      'areAllV2ResourcesSyncing',
      'areTranslationsSyncing',
      'areCountriesSyncing',
    ]),
  },
  methods: {
    onHubAccountsSync(): void {
      this.$store.dispatch('syncHubAccounts')
    },
    onAllResourcesSyncV3(): void {
      this.$store.dispatch('syncAllResourcesV3')
    },
    onAllResourcesSyncV2(): void {
      this.$store.dispatch('syncAllResourcesV2')
    },
    onTranslationsSync(): void {
      this.$store.dispatch('syncAllTranslations')
    },
    onAllCountriesSync(): void {
      this.$store.dispatch('syncAllCountries')
    },
  },
  components: {
    Page,
    Button,
    PageTop,
  },

})
