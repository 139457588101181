
import { orderBy } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

interface ISelectedItem {
  id: string
  title: string
}

interface ISuggestion {
  id: string
  title: string
  score?: number
}

export default defineComponent({
  name: 'TranslationSelect',
  props: {
    label: {
      type: String,
      required: false,
    },
    data: {
      type: [String, Array],
      required: true,
    },
    extraText: {
      type: String,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    defaultValue: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    maxResults: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      searchTerm: '',
      minSearchTermLength: 2,
      showSuggestions: false,
    }
  },
  computed: {
    ...mapGetters([
      'translationGroups',
      'selectedLanguage',
    ]),
    selectedItems(): ISelectedItem[] {
      // Data as string
      if (typeof this.data === 'string') {
        if (!this.data) {
          return []
        }

        return [
          {
            id: this.data,
            title: this.mixWB(this.data),
          },
        ]
      }
      // Data as array
      if (!(this.data as []).length) {
        return []
      }

      return this.data.map((x) => {
        const key = (x as string)
        return {
          id: key,
          title: this.translationGroups[this.selectedLanguage][key],
        }
      })
    },
    suggestions(): ISuggestion[] {
      // No search term
      if (this.searchTerm.length < this.minSearchTermLength) {
        return []
      }

      // With search term
      const suggestions: ISuggestion[] = Object.keys(
        this.translationGroups[this.selectedLanguage],
      ).reduce((prev: ISuggestion[], key: string) => {
        const searchTerm = this.searchTerm.toLowerCase()
        const text = this.translationGroups[this.selectedLanguage][key].toLowerCase()

        // Don't show already selected
        if (this.data.includes(key)) {
          return prev
        }

        // Don't show certain translations
        if (key.includes('EWC_17_')) {
          return prev
        }

        // Exact match
        if (text === searchTerm) {
          prev.push({
            id: key,
            title: this.translationGroups[this.selectedLanguage][key],
            score: 100,
          })
        }

        // Partial match
        else if (text.includes(searchTerm)) {
          prev.push({
            id: key,
            title: this.translationGroups[this.selectedLanguage][key],
            score: Math.ceil((searchTerm.length / text.length) * 100),
          })
        }

        return prev
      }, [])

      return orderBy(suggestions, ['score'], ['desc'])
    },
  },
  methods: {
    onSearchTermInput(): void {
      this.showSuggestions = this.searchTerm.length >= this.minSearchTermLength
    },
    onRemoveItem(id: string): void {
      // Data as string
      if (typeof this.data === 'string') {
        this.$emit('update:data', '')
        return
      }

      // Data as array
      const index = this.data.findIndex((x) => x === id)
      const dataCopy = [...this.data]
      dataCopy.splice(index, 1)
      this.$emit('update:data', dataCopy)
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion: ISuggestion): void {
      // Data as string
      if (typeof this.data === 'string') {
        this.$emit('update:data', suggestion.id)
      }
      // Data as array
      else {
        this.$emit('update:data', this.data.concat(suggestion.id))
      }

      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
})
