
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { TagV3 } from '@/globals/javascript/models/resources-version-3/TagV3'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TagsV3',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'tagsAsArrayV3',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.tagsAsArrayV3.forEach((tag: TagV3) => {
        rows.push({
          id: tag.id,
          title: this.mixWB(tag.translation),
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Tags')
    },
    onAddTagClick() {
      console.log('onAddTagClick')
    },
  },
  components: {
    Page, PageTop, DataTable,
  },
})
