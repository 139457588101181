
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import {
  IAnalysisDeliveryTimeOptionV3,
  IAnalysisTestGroupV3,
  IAnalysisTestV3,
  ILaboratoryV3,
  ISampleTypeV3,
} from '@/types/resources-version-3'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'LaboratoriesAndAnalysesV3',
  data() {
    return {
      laboratoryColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'phone',
          title: this.mixWB('PHONE'),
          canSort: true,
        },
        {
          key: 'requisitionEmail',
          title: this.mixWB('W: Email til rekvisition'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
      analysisDeliveryTimeColumns: [
        {
          key: 'order',
          title: this.mixWB('W: Rækkefølge'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'stanlabID',
          title: this.mixWB('W: StanlabID'),
          canSort: true,
        },
      ],
      sampleTypeColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'groupID',
          title: this.mixWB('W: Analyse gruppe ID'),
          canSort: true,
        },
        {
          key: 'stanlabID',
          title: this.mixWB('W: Stanlab ID'),
          canSort: true,
        },
      ],
      analysisTestGroupColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'analysisTestIDs',
          title: this.mixWB('W: Analyse test ID\'er'),
          canSort: true,
        },
      ],
      analysisTestColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'type',
          title: this.mixWB('W: Type af test'),
          canSort: true,
        },
        {
          key: 'minValue',
          title: this.mixWB('W: Forurenet skillepunkt'),
          canSort: true,
        },
        {
          key: 'stancodeID',
          title: this.mixWB('W: Stancode ID'),
          canSort: true,
        },
        {
          key: 'maxValue',
          title: this.mixWB('W: Farligt skillepunkt'),
          canSort: true,
        },
        {
          key: 'unit',
          title: this.mixWB('W: Målt i'),
          canSort: true,
        },
      ],
      ecoToxColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: Analyse test id'),
          canSort: true,
        },
      ],
      pahV1Columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: Analyse test id'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'laboratoriesAsArrayV3',
      'sampleTypesAsArrayV3',
      'analysisTestGroupsAsArrayV3',
      'analysisTestsAsArrayV3',
      'ecoToxAnalysisTestIDListAsArrayV3',
      'pahV1AnalysisTestIDListAsArrayV3',
      'analysisDeliveryTimeOptionsAsArrayV3',
    ]),
    laboratoryRows(): Row[] {
      const rows: Row[] = []
      this.laboratoriesAsArrayV3.forEach((laboratory: ILaboratoryV3) => {
        rows.push({
          title: this.mixWB(laboratory.translation),
          phone: laboratory.phone,
          requisitionEmail: laboratory.requisitionEmail,
          isActive: laboratory.isActive,
        })
      })
      return rows
    },
    analysisDeliveryTimeRows(): Row[] {
      const rows: Row[] = []
      this.analysisDeliveryTimeOptionsAsArrayV3.forEach((
        deliveryItem: IAnalysisDeliveryTimeOptionV3,
      ) => {
        let title = ''
        if (deliveryItem.days === 1) {
          title = this.mixWB('1_DAY_X_O_CLOCK', [deliveryItem.hours])
        }
        else {
          title = this.mixWB('X_DAYS_X_O_CLOCK', [deliveryItem.days, deliveryItem.hours])
        }
        rows.push({
          order: deliveryItem.order,
          title,
          id: deliveryItem.id,
          stanlabID: deliveryItem.stanlabID || '-',
        })
      })
      return rows
    },
    sampleTypeRows(): Row[] {
      const rows: Row[] = []
      this.sampleTypesAsArrayV3.forEach((sampleType: ISampleTypeV3) => {
        rows.push({
          id: sampleType.id,
          groupID: sampleType.analysisTestGroupID,
          title: this.mixWB(sampleType.translation),
          stanlabID: sampleType.stanlabID || '-',
        })
      })
      return rows
    },
    analysisTestGroupRows(): Row[] {
      const rows: Row[] = []
      this.analysisTestGroupsAsArrayV3.forEach((analysisTestGroup: IAnalysisTestGroupV3) => {
        rows.push({
          id: analysisTestGroup.id,
          title: this.mixWB(analysisTestGroup.translation),
          analysisTestIDs: analysisTestGroup.analysisTestIDs.join(', '),
        })
      })
      return rows
    },
    analysisTestRows(): Row[] {
      const rows: Row[] = []
      this.analysisTestsAsArrayV3.forEach((analysisTest: IAnalysisTestV3) => {
        rows.push({
          id: analysisTest.id,
          title: this.mixWB(analysisTest.translation),
          type: analysisTest.type === 'value-based'
            ? this.mixWB('W: Værdi baseret') : this.mixWB('W: Påvist / Ikke påvist'),
          minValue: analysisTest.minValue || '-',
          stancodeID: analysisTest.stancodeID || '-',
          maxValue: analysisTest.maxValue || '-',
          unit: analysisTest.unit || '-',
        })
      })
      return rows
    },
    ecoToxRows(): Row[] {
      const rows: Row[] = []
      this.ecoToxAnalysisTestIDListAsArrayV3.forEach((analysisTestID: string) => {
        const analysisTest = this.analysisTestsAsArrayV3.find(
          (x: IAnalysisTestV3) => x.id === analysisTestID,
        )

        rows.push({
          id: analysisTestID,
          title: this.mixWB(analysisTest.translation),
        })
      })
      return rows
    },
    pahV1Rows(): Row[] {
      const rows: Row[] = []
      this.pahV1AnalysisTestIDListAsArrayV3.forEach((analysisTestID: string) => {
        const analysisTest = this.analysisTestsAsArrayV3.find(
          (x: IAnalysisTestV3) => x.id === analysisTestID,
        )

        rows.push({
          id: analysisTestID,
          title: this.mixWB(analysisTest.translation),
        })
      })
      return rows
    },
  },
  methods: {
    add6Metals() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      // Update
      this.$store.dispatch('add6Metals')
    },
    updatePAHV2() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      // Update PAH analysis tests
      this.$store.dispatch('pahUpdatePAHAnalysisTests')

      // Update PAH analyses test group
      this.$store.dispatch('pahUpdatePAHAnalysisTestGroup')

      // Update EuroFins and Højvang excel file column names
      this.$store.dispatch('pahUpdateLabColumnNames')

      // Add list of PAH V1 analysis tests
      this.$store.dispatch('pahUpdateAddV1List')
    },
  },
  components: {
    Page, PageTop, Button, DataTable,
  },
})
