
import { defineComponent, shallowRef } from 'vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import Button from '@/components/Form/Button.vue'
import { mapGetters } from 'vuex'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewRoomDialogVue from '@/components/Modals/Dialogs/ResourcesV3/NewRoomDialogV3.vue'
import { RoomV3 } from '@/globals/javascript/models/resources-version-3/RoomV3'

export default defineComponent({
  name: 'RoomsV3',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'suggestionText',
          title: this.mixWB('W: Forslagstekst'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'isStandard',
          title: this.mixWB('W: Standard?'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Aktiv?'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      dialogProps: {
        roomToEdit: null,
      },
      NewRoomDialogV3: shallowRef(NewRoomDialogVue),
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'roomsAsArrayV3',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.roomsAsArrayV3.forEach((room: RoomV3) => {
        rows.push({
          id: room.id,
          title: this.mixWB(room.translation),
          suggestionText: this.mixWB(room.suggestionText),
          isStandard: room.options.isStandard,
          isActive: room.options.isActive,
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Rooms')
    },
    onAddRoomClick() {
      this.showDialog = true
      this.dialogProps.roomToEdit = null
    },
    onEditRoomClick(roomID: string) {
      const room = this.roomsAsArrayV3.find((x: RoomV3) => x.id === roomID)
      this.dialogProps.roomToEdit = room
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },
})
