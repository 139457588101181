
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import MaterialForm from '@/components/Forms/ResourcesV2/MaterialFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import ResourceChangeLogV2 from '@/components/ResourceChangeLogV2.vue'
import { IMaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import { ITypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'MaterialEditV2',
  data() {
    return {
      typeColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'materialsAsArrayV2',
      'typesAsArrayV2',
    ]),
    materialToEdit(): IMaterialV2 {
      const { materialID } = this.$route.params

      return this.materialsAsArrayV2.find((x: IMaterialV2) => x.id === materialID)
    },
    typeRows(): Row[] {
      return this.typesAsArrayV2.reduce((prev: Row[], type: ITypeV2) => {
        if (type.materialIDs.includes(this.materialToEdit.id)) {
          prev.push({
            id: type.id,
            title: this.mixWB(type.translation),
          })
        }

        return prev
      }, [])
    },
  },
  methods: {
    onEditTypeClick(typeID: string): void {
      this.$router.push({ name: 'TypeEditV2', params: { typeID } })
    },
  },
  components: {
    Page,
    PageTop,
    MaterialForm,
    DataTable,
    Edit,
    ResourceChangeLogV2,
  },

})
