
import { defineComponent } from 'vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { mapGetters } from 'vuex'
import CategoryTypeForm from '@/components/Form/CategoryTypeForm.vue'
import { ICategoryTypeV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import Button from '@/components/Form/Button.vue'
import ResourceChangeLogV2 from '@/components/ResourceChangeLogV2.vue'

export default defineComponent({
  name: 'CategoryTypeEditV2',
  computed: {
    ...mapGetters([
      'categoryTypesAsArrayV2',
    ]),
    categoryTypeToEdit(): ICategoryTypeV2 {
      const { categoryTypeID } = this.$route.params

      return this.categoryTypesAsArrayV2.find((x: ICategoryTypeV2) => x.id === categoryTypeID)
    },
  },
  components: {
    Page,
    PageTop,
    CategoryTypeForm,
    Button,
    ResourceChangeLogV2,
  },
})
