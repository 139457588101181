
import DataTable, { Column, Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { Component, defineComponent, shallowRef } from 'vue'
import NewQuestionDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewQuestionDialogV3.vue'
import NewGroupQuestionDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewGroupQuestionDialogV3.vue'
import { mapGetters } from 'vuex'
import { QuestionV3 } from '@/globals/javascript/models/resources-version-3/QuestionV3'
import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'

export default defineComponent({
  name: 'QuestionsV3',
  data() {
    return {
      questionColumns: [
        {
          key: 'question',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'samples',
          title: this.mixWB('W: Prøver'),
        },
        {
          key: 'tags',
          title: this.mixWB('W: Tags'),
        },
        {
          key: 'assessments',
          title: this.mixWB('W: Vurderinger'),
        },
        {
          key: 'noOfTypes',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'noOfGroups',
          title: this.mixWB('W: Grupper'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ] as Column[],
      questionGroupColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'noOfQuestions',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'noOfTypes',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ] as Column[],
      // Dialog
      showDialog: false,
      dialogProps: {
        questionToEdit: null,
        groupQuestionToEdit: null,
      },
      dialogComponent: {} as Component,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'questionsAsArrayV3',
      'groupQuestionsAsArrayV3',
    ]),
    questionRows(): Row[] {
      return this.questionsAsArrayV3.map((question: QuestionV3) => (
        {
          id: question.id,
          question: question.getTitle(),
          samples: question.getSamples(),
          tags: question.getTags(),
          assessments: question.getAssessments(),
          noOfTypes: question.getNoOfTypes().toString(),
          noOfGroups: question.getNoOfGroups().toString(),
        }
      ))
    },
    questionGroupRows(): Row[] {
      return this.groupQuestionsAsArrayV3.map((groupQuestion: GroupQuestionV3) => (
        {
          id: groupQuestion.id,
          title: this.mixWB(groupQuestion.translation),
          noOfQuestions: groupQuestion.questions.length.toString(),
          noOfTypes: groupQuestion.getNoOfTypes().toString(),
        }
      ))
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Questions')
      this.$store.dispatch('uploadV3GroupQuestions')
    },
    onAddSingleQuestionClick(): void {
      this.dialogComponent = shallowRef(NewQuestionDialogV3)
      this.showDialog = true
      this.dialogProps.questionToEdit = null
      this.dialogProps.groupQuestionToEdit = null
    },
    onSingleQuestionEditClick(qusetionID: string): void {
      this.dialogComponent = shallowRef(NewQuestionDialogV3)
      const question = this.questionsAsArrayV3.find((x: QuestionV3) => x.id === qusetionID)
      this.dialogProps.questionToEdit = question
      this.dialogProps.groupQuestionToEdit = null
      this.showDialog = true
    },
    onAddGroupQuestionClick(): void {
      this.dialogComponent = shallowRef(NewGroupQuestionDialogV3)
      this.showDialog = true
      this.dialogProps.questionToEdit = null
      this.dialogProps.groupQuestionToEdit = null
    },
    onGroupQuestionEditClick(groupQuestionID: string): void {
      this.dialogComponent = shallowRef(NewGroupQuestionDialogV3)
      const groupQuestion = this.groupQuestionsAsArrayV3.find(
        (x: GroupQuestionV3) => x.id === groupQuestionID,
      )
      this.dialogProps.questionToEdit = null
      this.dialogProps.groupQuestionToEdit = groupQuestion
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },

})
