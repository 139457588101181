
import ItemFormV3 from '@/components/Forms/ResourcesV3/ItemFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import { ItemV3 } from '@/globals/javascript/models/resources-version-3/ItemV3'

export default defineComponent({
  name: 'NewTypeDialogV3',
  props: {
    itemToEdit: {
      type: Object as PropType<ItemV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    ItemFormV3,
  },

})
