
import {
  CategoryTypeV2, ICategoryTypeV2, ICategoryTypeMaterialDataItemV2,
} from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import InputField from '@/components/Form/InputField.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import Divider from '@/components/Form/Divider.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import Toggle from '@/components/Form/Toggle.vue'
import { ITypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import Button from '@/components/Form/Button.vue'
import { sortBy } from 'lodash-es'
import { updateLog } from '@/globals/javascript/utils/helpers'
import { ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { MaterialV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import Toggle3Way from '@/components/Form/Toggle3Way.vue'

export default defineComponent({
  name: 'CategoryTypeForm',
  props: {
    categoryTypeToEdit: {
      type: Object as PropType<ICategoryTypeV2>,
      required: false,
    },
    preSelectedCategoryID: {
      type: String,
      required: false,
    },
    preSelectedTypeID: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      categoryType: {} as ICategoryTypeV2,
      categoryError: '',
      typeError: '',
      amountError: '',
      translationError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextCategoryTypeIDV2',
      'categoriesAsArrayV2',
      'typesAsArrayV2',
      'categoryTypesAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.categoryTypeToEdit
    },
    selectedCategory(): ICategoryTypeV2 {
      return this.categoriesAsArrayV2.find(
        (x: ICategoryV2) => x.id === this.categoryType.categoryID,
      )
    },
    selectedType(): ITypeV2 {
      return this.typesAsArrayV2.find(
        (x: ITypeV2) => x.id === this.categoryType.typeID,
      )
    },
    showPlasterToggle(): boolean {
      return this.selectedCategory?.options.askForPlaster
    },
    showWallpaperToggle(): boolean {
      return this.selectedCategory?.options.askForWallpaper
    },
    defaultTranslation(): string {
      if (!this.selectedType) {
        return ''
      }

      return this.mixWB(this.selectedType?.translation)
    },
  },
  methods: {
    setDataOnLoad() {
      // Edit
      if (this.categoryTypeToEdit) {
        this.categoryType = new CategoryTypeV2(this.categoryTypeToEdit)
        return
      }

      // New
      this.categoryType = CategoryTypeV2.newInstance(this.nextCategoryTypeIDV2)
      this.categoryType.categoryID = this.preSelectedCategoryID || ''
      this.categoryType.typeID = this.preSelectedTypeID || ''
    },
    getMaterialTitle(materialID: string) {
      return MaterialV2.getTitleFromID(materialID)
    },
    onCategoryUpdate() {
      this.categoryError = ''
      // Check underlining
      if (this.selectedCategory?.id !== 'C-1') {
        this.categoryType.options.askForUnderlining = false
      }
      // Check plaster
      if (this.selectedCategory?.options.askForPlaster) {
        this.categoryType.options.askForPlaster = true
      }
      else {
        this.categoryType.options.askForPlaster = false
      }
      // Check plaster
      if (this.selectedCategory?.options.askForWallpaper) {
        this.categoryType.options.askForWallpaper = true
      }
      else {
        this.categoryType.options.askForWallpaper = false
      }
    },
    onTypeUpdate() {
      this.typeError = ''
      this.categoryType.materialData = this.categoryType.setMaterialData()
    },
    onValidation() {
      let allGood = true

      // Category
      if (!this.categoryType.categoryID) {
        this.categoryError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      // Type
      if (!this.categoryType.typeID) {
        this.typeError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      // Material data - amounts
      if (Object.keys(this.categoryType.materialData).length) {
        let materialDataAmountsGood = true
        Object.keys(this.categoryType.materialData).forEach((materialID: string) => {
          const materialData:
            ICategoryTypeMaterialDataItemV2 = this.categoryType.materialData[materialID]
          Object.keys(materialData.units).forEach((unitID) => {
            const unit = materialData.units[(unitID as 'm2' | 'meters' | 'pcs')]
            if (!materialDataAmountsGood) {
              return
            }
            if (unit.status !== 2) {
              return
            }
            materialDataAmountsGood = Object.keys(unit.fields).every((fieldKey: string) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const field = (unit.fields as any)[fieldKey]

              return field.value > 0
            })
          })
        })

        if (!materialDataAmountsGood) {
          this.amountError = this.mixWB('W: Nogle mængder er ikke udfyldt korrekt')
          allGood = false
        }
      }

      // Translation
      if (this.categoryType.typeID && this.categoryType.translation) {
        const categoryTypeText = this.mixWB(this.categoryType.translation)
        const typeText = this.mixWB(this.selectedType.translation)

        if (categoryTypeText === typeText) {
          this.translationError = this.mixWB('W: Kan ikke være det samme som den type du har valgt. Lad feltet stå tomt i stedet.')
          allGood = false
        }
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newCategoryTypes: ICategoryTypeV2[] = []
      this.categoryTypesAsArrayV2.forEach((categoryType: ICategoryTypeV2) => {
        if (categoryType.id === this.categoryType.id) {
          return
        }

        newCategoryTypes.push({ ...categoryType })
      })

      // Add new log
      updateLog({
        newItem: this.categoryType,
        oldItem: this.categoryTypeToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) categoryType
      newCategoryTypes.push({ ...this.categoryType })

      // Save type
      this.$store.dispatch('setResourceV2', {
        doc: '-category-types-',
        data: sortBy(newCategoryTypes, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    SearchSelect,
    Divider,
    Toggle,
    TranslationSelect,
    Button,
    Toggle3Way,
  },
  created() {
    this.setDataOnLoad()
  },
})
