
import DataTable, { Column, Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { Component, defineComponent, shallowRef } from 'vue'
import NewQuestionDialog from '@/components/Modals/Dialogs/ResourcesV2/NewQuestionDialogV2.vue'
import NewGroupQuestionDialog from '@/components/Modals/Dialogs/ResourcesV2/NewGroupQuestionDialogV2.vue'
import { mapGetters } from 'vuex'
import { IQuestionV2 } from '@/globals/javascript/models/resources-version-2/QuestionV2'
import { IGroupQuestionV2 } from '@/globals/javascript/models/resources-version-2/GroupQuestionV2'

export default defineComponent({
  name: 'QuestionsV2',
  data() {
    return {
      questionColumns: [
        {
          key: 'question',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'samples',
          title: this.mixWB('W: Prøver'),
        },
        {
          key: 'tags',
          title: this.mixWB('W: Tags'),
        },
        {
          key: 'assessments',
          title: this.mixWB('W: Vurderinger'),
        },
        {
          key: 'noOfTypes',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ] as Column[],
      questionGroupColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'noOfQuestions',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'noOfTypes',
          title: this.mixWB('W: Typer'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ] as Column[],
      // Dialog
      showDialog: false,
      dialogComponent: {} as Component,
    }
  },
  computed: {
    ...mapGetters([
      'questionsAsArrayV2',
      'groupQuestionsAsArrayV2',
    ]),
    questionRows(): Row[] {
      return this.questionsAsArrayV2.map((question: IQuestionV2) => (
        {
          id: question.id,
          question: question.getTitle(),
          samples: question.getSamples(),
          tags: question.getTags(),
          assessments: question.getAssessments(),
          noOfTypes: question.getNoOfTypes().toString(),
        }
      ))
    },
    questionGroupRows(): Row[] {
      return this.groupQuestionsAsArrayV2.map((groupQuestion: IGroupQuestionV2) => (
        {
          id: groupQuestion.id,
          title: this.mixWB(groupQuestion.translation),
          noOfQuestions: groupQuestion.questions.length.toString(),
          noOfTypes: groupQuestion.getNoOfTypes().toString(),
        }
      ))
    },
  },
  methods: {
    onAddSingleQuestionClick(): void {
      this.dialogComponent = shallowRef(NewQuestionDialog)
      this.showDialog = true
    },
    onAddGroupQuestionClick(): void {
      this.dialogComponent = shallowRef(NewGroupQuestionDialog)
      this.showDialog = true
    },
    onSingleQuestionEditClick(questionID: string): void {
      this.$router.push({ name: 'QuestionEditV2', params: { questionID } })
    },
    onGroupQuestionEditClick(groupQuestionID: string): void {
      this.$router.push({ name: 'GroupQuestionEditV2', params: { groupQuestionID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },

})
