
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import { TypeGroupV3 } from '@/globals/javascript/models/resources-version-3/TypeGroupV3'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TypeGroupFormV3',
  props: {
    typeGroupToEdit: {
      type: Object as PropType<TypeGroupV3>,
      required: false,
    },
  },
  data() {
    return {
      typeGroup: {} as TypeGroupV3,
      isLoading: false,
      internalLabelError: '',
      translationError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextTypeGroupIDV3',
      'typesAsArrayV3',
      'typeGroupsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.typeGroupToEdit
    },
  },
  methods: {
    setTypeGroupOnLoad() {
      // Edit
      if (this.typeGroupToEdit) {
        this.typeGroup = new TypeGroupV3(copyObject(this.typeGroupToEdit))
        return
      }
      // New
      this.typeGroup = new TypeGroupV3({ id: this.nextTypeGroupIDV3 })
    },
    onValidation(): boolean {
      let allGood = true

      // Internal label
      if (allGood && !this.typeGroup.internalLabel) {
        this.internalLabelError = this.mixWB('W: Denne mangler')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(
          this.typeGroup.internalLabel,
          this.typeGroupsAsArrayV3,
          this.typeGroup.id,
          'internalLabel',
        )
      ) {
        this.internalLabelError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Translation
      if (allGood && !this.typeGroup.translation) {
        this.translationError = this.mixWB('W: Oversættelse mangler')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newTypeGroups: TypeGroupV3[] = []
      this.typeGroupsAsArrayV3.forEach((typeGroup: TypeGroupV3) => {
        if (typeGroup.id === this.typeGroup.id) {
          return
        }

        newTypeGroups.push(new TypeGroupV3(copyObject(typeGroup)))
      })

      // Add new (or edited) typeGroup
      newTypeGroups.push(this.typeGroup)

      // Save typeGroup
      this.$store.dispatch('setResourceV3', {
        doc: '-type-groups-',
        data: sortBy(newTypeGroups, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    SearchSelect,
    Toggle,
    Button,
  },
  created() {
    this.setTypeGroupOnLoad()
  },
})
