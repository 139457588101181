
import { mapGetters } from 'vuex'
import { defineComponent, PropType } from 'vue'
import InputField from '@/components/Form/InputField.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import QuestionSelect from '@/components/Form/QuestionSelect/QuestionSelect.vue'
import Button from '@/components/Form/Button.vue'
import Divider from '@/components/Form/Divider.vue'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'

export default defineComponent({
  name: 'GroupQuestionFormV3',
  props: {
    groupQuestionToEdit: {
      type: Object as PropType<GroupQuestionV3>,
      required: false,
    },
  },
  data() {
    return {
      groupQuestion: {} as GroupQuestionV3,
      isLoading: false,
      translationError: '',
      questionsError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextGroupQuestionIDV3',
      'groupQuestionsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.groupQuestionToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.groupQuestionToEdit) {
        this.groupQuestion = new GroupQuestionV3(copyObject(this.groupQuestionToEdit))
        return
      }

      // New
      this.groupQuestion = new GroupQuestionV3({ id: this.nextGroupQuestionIDV3 })
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.groupQuestion.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(
          this.groupQuestion.translation,
          this.groupQuestionsAsArrayV3,
          this.groupQuestion.id,
        )
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Questions
      if (allGood && !this.groupQuestion.questions.length) {
        this.questionsError = this.mixWB('W: Mangler spørgsmål')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newGroupQuestions: GroupQuestionV3[] = []
      this.groupQuestionsAsArrayV3.forEach((groupQuestion: GroupQuestionV3) => {
        if (groupQuestion.id === this.groupQuestion.id) {
          return
        }

        newGroupQuestions.push(new GroupQuestionV3(copyObject(groupQuestion)))
      })

      // Add new (or edited) groupQuestion
      newGroupQuestions.push(this.groupQuestion)

      // Save groupQuestion
      this.$store.dispatch('setResourceV3', {
        doc: '-group-questions-',
        data: sortBy(newGroupQuestions, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    QuestionSelect,
    Button,
    Divider,
  },
  created() {
    this.setDataOnLoad()
  },
})
