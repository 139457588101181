
import FractionForm from '@/components/Forms/ResourcesV2/FractionFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import ResourceChangeLogV2 from '@/components/ResourceChangeLogV2.vue'
import { IFractionV2 } from '@/globals/javascript/models/resources-version-2/FractionV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'FractionEditV2',
  computed: {
    ...mapGetters([
      'fractionsAsArrayV2',
    ]),
    fractionToEdit(): IFractionV2 {
      const { fractionID } = this.$route.params

      return this.fractionsAsArrayV2.find((x: IFractionV2) => x.id === fractionID)
    },
  },
  components: {
    Page,
    PageTop,
    FractionForm,
    ResourceChangeLogV2,
  },

})
