
import { orderBy, sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Form/Button.vue'
import { QuestionV3 } from '@/globals/javascript/models/resources-version-3/QuestionV3'
import { GroupQuestionV3 } from '@/globals/javascript/models/resources-version-3/GroupQuestionV3'
import { ITypeQuestionV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import QuestionSelectedItem from './QuestionSelectedItem.vue'

export interface IQuestionSelectSuggestion {
  id: string
  title: string
  samples?: string
  tags?: string
  assessments?: string
  isGroupQuestion: boolean
  noOfQuestion?: number
  score?: number
}

export default defineComponent({
  name: 'QuestionSelect',
  props: {
    label: {
      type: String,
      required: false,
    },
    data: {
      type: Array as PropType<ITypeQuestionV3[]>,
      required: true,
    },
    hideGroupQuestions: {
      type: Boolean,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
    }
  },
  computed: {
    ...mapGetters([
      'questionsAsArrayV3',
      'groupQuestionsAsArrayV3',
    ]),
    groupsAndQuestionsToUse(): (QuestionV3 | GroupQuestionV3)[] {
      const items:(QuestionV3|GroupQuestionV3)[] = []

      // Add questions
      this.questionsAsArrayV3.forEach((question: QuestionV3) => {
        const isSelected = this.data.find(
          (x: ITypeQuestionV3) => x.questionID === question.id,
        )

        if (isSelected) {
          return
        }

        items.push(question)
      })

      return items
    },
    nextPosition(): number {
      return this.data.length + 1
    },
    selectedItems(): {[key:string]: string | boolean | number}[] {
      return this.data.map((typeQuestion: ITypeQuestionV3) => {
        // Group question
        if (typeQuestion.questionID.includes('GQ-')) {
          const groupQuestion: GroupQuestionV3 = this.groupQuestionsAsArrayV3.find(
            (x: GroupQuestionV3) => x.id === typeQuestion.questionID,
          )

          return {
            id: typeQuestion.questionID,
            title: `${ this.mixWB('W: Gruppe') }: ${ this.mixWB(groupQuestion.translation) }`,
            samples: '',
            tags: '',
            assessments: '',
            alwaysShow: typeQuestion.alwaysShow,
            noOfQuestion: groupQuestion.questions.length,
            isGroupQuestion: true,
          }
        }

        // Question
        const question: QuestionV3 = this.questionsAsArrayV3.find(
          (x: QuestionV3) => x.id === typeQuestion.questionID,
        )

        return {
          id: typeQuestion.questionID,
          title: question.getTitle(),
          samples: question.getSamples(),
          tags: question.getTags(),
          assessments: question.getAssessments(),
          alwaysShow: typeQuestion.alwaysShow,
          noOfQuestion: 0,
          isGroupQuestion: false,
        }
      })
    },
    suggestions(): IQuestionSelectSuggestion[] {
      const searchTerm = this.searchTerm.toLowerCase()
      let suggestions: IQuestionSelectSuggestion[] = []

      // Add questions
      this.questionsAsArrayV3.forEach((question: QuestionV3) => {
        const isSelected = this.data.find(
          (x: ITypeQuestionV3) => x.questionID === question.id,
        )

        if (isSelected) {
          return
        }

        suggestions.push({
          id: question.id,
          title: question.getTitle(),
          samples: question.getSamples(),
          tags: question.getTags(),
          assessments: question.getAssessments(),
          isGroupQuestion: false,
        })
      })

      // Add group questions
      if (!this.hideGroupQuestions) {
        this.groupQuestionsAsArrayV3.forEach((groupQuestion: GroupQuestionV3) => {
          const isSelected = this.data.find(
            (x: ITypeQuestionV3) => x.questionID === groupQuestion.id,
          )

          if (isSelected) {
            return
          }

          suggestions.push({
            id: groupQuestion.id,
            title: `${ this.mixWB('W: Gruppe') }: ${ this.mixWB(groupQuestion.translation) }`,
            isGroupQuestion: true,
            noOfQuestion: groupQuestion.questions.length,
          })
        })
      }

      // Filter by search term
      if (searchTerm) {
        const unFilteredSuggestions: IQuestionSelectSuggestion[] = [...suggestions]
        suggestions = unFilteredSuggestions.reduce((
          prev: IQuestionSelectSuggestion[],
          suggestion: IQuestionSelectSuggestion,
        ) => {
          const text = suggestion.title.toLowerCase()

          if (text.includes(searchTerm)) {
            suggestion.score = Math.ceil((searchTerm.length / text.length) * 100)
            prev.push(suggestion)
          }

          return prev
        }, [])
      }

      return orderBy(suggestions, ['score', 'title'], ['desc', 'asc'])
    },
  },
  methods: {
    onAddQuestionClick(): void {
      this.showSuggestions = !this.showSuggestions

      requestAnimationFrame(() => {
        if (this.showSuggestions) {
          (this.$refs as HTMLFormElement).SearchField.focus()
        }
      })
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(questionID: string): void {
      const newData: ITypeQuestionV3[] = [...this.data]
      newData.push({
        questionID,
        position: this.nextPosition,
        alwaysShow: false,
      } as ITypeQuestionV3)

      this.onEmitData(newData)

      this.searchTerm = ''
      this.showSuggestions = false
    },
    onArrowClick({ index, direction }: { index: number, direction: string }): void {
      const newData: ITypeQuestionV3[] = [...this.data]

      if (direction === 'up') {
        if (index === 0) {
          return
        }
        newData[index].position = index - 1
        newData[index - 1].position = index
      }

      if (direction === 'down') {
        if (index === this.selectedItems.length - 1) {
          return
        }
        newData[index].position = index + 1
        newData[index + 1].position = index
      }

      this.onEmitData(sortBy(newData, 'position'))
    },
    onRemoveQuestion(index: number): void {
      const newData: ITypeQuestionV3[] = [...this.data]
      newData.splice(index, 1)

      this.onEmitData(newData)
    },
    onAlwaysShowToggle(index: number): void {
      const newData: ITypeQuestionV3[] = [...this.data]
      newData[index].alwaysShow = !newData[index].alwaysShow
      this.onEmitData(newData)
    },
    onEmitData(newData: ITypeQuestionV3[]): void {
      newData.forEach((item: ITypeQuestionV3, index: number) => {
        if (index === 0) {
          item.alwaysShow = false
        }
        item.position = index
      })

      this.$emit('update:data', newData)
    },
  },
  components: {
    Button,
    QuestionSelectedItem,
  },
})
