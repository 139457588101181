<template>
  <div class="NewCategoryTypeDialog">
    <DialogTitle>
      {{ mixWB('W: Tilføj kategori-type') }}
    </DialogTitle>

    <CategoryTypeForm
      :preSelectedCategoryID="preSelectedCategoryID"
      :preSelectedTypeID="preSelectedTypeID"
      @submitted="onClose" />
  </div>
</template>

<script>
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import CategoryTypeForm from '@/components/Form/CategoryTypeForm.vue'

export default {
  name: 'NewCategoryTypeDialog',
  props: {
    preSelectedCategoryID: {
      type: String,
      required: false,
    },
    preSelectedTypeID: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    CategoryTypeForm,
  },

}
</script>

<style lang="stylus" scoped>
  .NewCategoryTypeDialog
    display block
</style>
