
import { FracionTypesV2, IFractionV2 } from '@/globals/javascript/models/resources-version-2/FractionV2'
import { ObjPairString } from '@/types/general'
import { orderBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

interface ISuggestion {
  id: string
  title: string
  score?: number
}

export default defineComponent({
  name: 'FractionSelectV2',
  props: {
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<FracionTypesV2>,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
    }
  },
  computed: {
    ...mapGetters([
      'fractionsAsArrayV2',
    ]),
    filteredFractions(): IFractionV2[] {
      const type: FracionTypesV2 = ['clean', 'contaminated'].includes(this.type)
        ? this.type
        : FracionTypesV2.OTHER
      return this.fractionsAsArrayV2.filter((x: IFractionV2) => x.types.includes(type))
    },
    selectedItems(): ObjPairString[] {
      if (!this.data) {
        return []
      }

      const item: IFractionV2 = this.fractionsAsArrayV2.find((x: IFractionV2) => x.id === this.data)

      return [
        {
          title: this.mixWB(item.translation),
        },
      ]
    },
    suggestions(): ISuggestion[] {
      const suggestions: ISuggestion[] = []
      const searchTerm = this.searchTerm.toLowerCase()

      // No search term
      if (!searchTerm) {
        this.filteredFractions.forEach((fraction: IFractionV2) => {
          suggestions.push({
            id: fraction.id,
            title: this.mixWB(fraction.translation),
          })
        })
      }

      // With search term
      else {
        this.filteredFractions.forEach((fraction: IFractionV2) => {
          const text = this.mixWB(fraction.translation).toLowerCase()

          // Exact match
          if (text === searchTerm) {
            suggestions.push({
              id: fraction.id,
              title: this.mixWB(fraction.translation),
              score: 100,
            })
          }
          // Partial match
          else if (text.includes(searchTerm)) {
            suggestions.push({
              id: fraction.id,
              title: this.mixWB(fraction.translation),
              score: Math.ceil((searchTerm.length / text.length) * 100),
            })
          }
        })
      }

      return orderBy(suggestions, ['score', 'title'], ['desc', 'asc'])
    },
  },
  methods: {
    onSearchTermInput(): void {
      this.showSuggestions = true
    },
    onRemoveItem(): void {
      this.$emit('update:data', '')
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion: ISuggestion): void {
      this.$emit('update:data', suggestion.id)
      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
})
