
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import NewTypeDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewTypeDialogV3.vue'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Edit from '@/components/DataTable/Edit.vue'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'

export default defineComponent({
  name: 'TypesV3',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'sampleIDs',
          title: this.mixWB('W: Prøver'),
          canSort: true,
        },
        {
          key: 'noOfQuestions',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'aliases',
          title: this.mixWB('W: Aliasser'),
          canSort: true,
        },
        {
          key: 'isNeverAlone',
          title: this.mixWB('W: Er aldrig alene'),
          canSort: true,
        },
        {
          key: 'isShownAlone',
          title: this.mixWB('W: Vises for sig'),
          canSort: true,
        },
        {
          key: 'isNotRecyclable',
          title: this.mixWB('W: Kan ikke genanvendes'),
          canSort: true,
        },
        {
          key: 'isCleanable',
          title: this.mixWB('W: Kan afrenses'),
          canSort: true,
        },
        {
          key: 'isDusty',
          title: this.mixWB('W: Er støvende'),
          canSort: true,
        },
        {
          key: 'isTile',
          title: this.mixWB('W: Fliser/Klinker'),
          canSort: true,
        },
        {
          key: 'material',
          title: this.mixWB('W: Materiale'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      dialogProps: {
        typeToEdit: null,
      },
      NewTypeDialogV3: shallowRef(NewTypeDialogV3),
    }
  },
  computed: {
    ...mapGetters([
      'typesAsArrayV3',
      'currentUser',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.typesAsArrayV3.forEach((type: TypeV3) => {
        const user = type.getUser()
        const material = type.getMaterial()

        rows.push({
          title: this.mixWB(type.translation),
          id: type.id,
          sampleIDs: type.sampleIDs.length.toString(),
          noOfQuestions: type.questions.length.toString(),
          aliases: type.aliases.length.toString(),
          isNeverAlone: type.options.isNeverAlone,
          isShownAlone: type.options.isShownAlone,
          isNotRecyclable: type.options.isNotRecyclable,
          isCleanable: type.options.isCleanable,
          isDusty: type.options.isDusty,
          isTile: type.options.isTile,
          material: material ? this.mixWB(material.translation) : '',
          isActive: type.options.isActive,
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Types')
    },
    onAddTypeClick() {
      this.showDialog = true
      this.dialogProps.typeToEdit = null
    },
    onEditTypeClick(typeID: string): void {
      const type = this.typesAsArrayV3.find((x: TypeV3) => x.id === typeID)
      this.dialogProps.typeToEdit = type
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    Dialog,
    DataTable,
    Edit,
  },
})
