
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import {
  IAnalysisDeliveryTimeOptionV2,
  IAnalysisTestV2, IAnalysisTestGroupV2, ILaboratoryV2, ISampleTypeV2,
} from '@/types/resources-version-2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'LaboratoriesAndAnalysesV2',
  data() {
    return {
      laboratoryColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'phone',
          title: this.mixWB('PHONE'),
          canSort: true,
        },
        {
          key: 'requisitionEmail',
          title: this.mixWB('W: Email til rekvisition'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
      analysisDeliveryTimeColumns: [
        {
          key: 'order',
          title: this.mixWB('W: Rækkefølge'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
      ],
      sampleTypeColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'groupID',
          title: this.mixWB('W: Analyse gruppe ID'),
          canSort: true,
        },
      ],
      analysisTestGroupColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'analysisTestIDs',
          title: this.mixWB('W: Analyse test ID\'er'),
          canSort: true,
        },
      ],
      analysisTestColumns: [
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'type',
          title: this.mixWB('W: Type af test'),
          canSort: true,
        },
        {
          key: 'minValue',
          title: this.mixWB('W: Forurenet skillepunkt'),
          canSort: true,
        },
        {
          key: 'maxValue',
          title: this.mixWB('W: Farligt skillepunkt'),
          canSort: true,
        },
        {
          key: 'unit',
          title: this.mixWB('W: Målt i'),
          canSort: true,
        },
      ],
      ecoToxColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: Analyse test id'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'laboratoriesAsArrayV2',
      'sampleTypesAsArrayV2',
      'analysisTestGroupsAsArrayV2',
      'analysisTestsAsArrayV2',
      'ecoToxAnalysisTestIDListAsArrayV2',
      'analysisDeliveryTimeOptionsAsArrayV2',
    ]),
    laboratoryRows(): Row[] {
      const rows: Row[] = []
      this.laboratoriesAsArrayV2.forEach((laboratory: ILaboratoryV2) => {
        rows.push({
          title: this.mixWB(laboratory.translation),
          phone: laboratory.phone,
          requisitionEmail: laboratory.requisitionEmail,
          isActive: laboratory.isActive,
        })
      })
      return rows
    },
    analysisDeliveryTimeRows(): Row[] {
      const rows: Row[] = []
      this.analysisDeliveryTimeOptionsAsArrayV2.forEach((
        deliveryItem: IAnalysisDeliveryTimeOptionV2,
      ) => {
        let title = ''
        if (deliveryItem.days === 1) {
          title = this.mixWB('1_DAY_X_O_CLOCK', [deliveryItem.hours])
        }
        else {
          title = this.mixWB('X_DAYS_X_O_CLOCK', [deliveryItem.days, deliveryItem.hours])
        }
        rows.push({
          order: deliveryItem.order,
          title,
          id: deliveryItem.id,
        })
      })
      return rows
    },
    sampleTypeRows(): Row[] {
      const rows: Row[] = []
      this.sampleTypesAsArrayV2.forEach((sampleType: ISampleTypeV2) => {
        rows.push({
          id: sampleType.id,
          groupID: sampleType.analysisTestGroupID,
          title: this.mixWB(sampleType.translation),
        })
      })
      return rows
    },
    analysisTestGroupRows(): Row[] {
      const rows: Row[] = []
      this.analysisTestGroupsAsArrayV2.forEach((analysisTestGroup: IAnalysisTestGroupV2) => {
        rows.push({
          id: analysisTestGroup.id,
          title: this.mixWB(analysisTestGroup.translation),
          analysisTestIDs: analysisTestGroup.analysisTestIDs.join(', '),
        })
      })
      return rows
    },
    analysisTestRows(): Row[] {
      const rows: Row[] = []
      this.analysisTestsAsArrayV2.forEach((analysisTest: IAnalysisTestV2) => {
        rows.push({
          id: analysisTest.id,
          title: this.mixWB(analysisTest.translation),
          type: analysisTest.type === 'value-based'
            ? this.mixWB('W: Værdi baseret') : this.mixWB('W: Påvist / Ikke påvist'),
          minValue: analysisTest.minValue || '-',
          maxValue: analysisTest.maxValue || '-',
          unit: analysisTest.unit || '-',
        })
      })
      return rows
    },
    ecoToxRows(): Row[] {
      const rows: Row[] = []
      this.ecoToxAnalysisTestIDListAsArrayV2.forEach((analysisTestID: string) => {
        const analysisTest = this.analysisTestsAsArrayV2.find(
          (x: IAnalysisTestV2) => x.id === analysisTestID,
        )

        rows.push({
          id: analysisTestID,
          title: this.mixWB(analysisTest.translation),
        })
      })
      return rows
    },
  },
  components: {
    Page, PageTop, DataTable,
  },
})
