
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import Button from '@/components/Form/Button.vue'
import { mapGetters } from 'vuex'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import { defineComponent, shallowRef } from 'vue'
import { FracionTypesV3 } from '@/types/resources-version-3'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import NewFractionDialogV3Vue from '@/components/Modals/Dialogs/ResourcesV3/NewFractionDialogV3.vue'
import Dialog from '@/components/Modals/Dialog.vue'

export default defineComponent({
  name: 'FractionsV3',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'clean',
          title: this.mixWB('CLEAN'),
          canSort: true,
        },
        {
          key: 'contaminated',
          title: this.mixWB('CONTAMINATED_2'),
          canSort: true,
        },
        {
          key: 'other',
          title: this.mixWB('OTHER'),
          canSort: true,
        },
        {
          key: 'avgPrice',
          title: this.mixWB('W: Gen. pris'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],

      // Dialog
      showDialog: false,
      dialogProps: {
        fractionToEdit: null,
      },
      NewFractionDialogV3: shallowRef(NewFractionDialogV3Vue),
    }
  },
  computed: {
    ...mapGetters([
      'fractionsAsArrayV3',
      'currentUser',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.fractionsAsArrayV3.forEach((fraction: FractionV3) => {
        const user = fraction.getUser()

        rows.push({
          title: this.mixWB(fraction.translation),
          id: fraction.id,
          clean: fraction.types.includes(FracionTypesV3.CLEAN),
          contaminated: fraction.types.includes(FracionTypesV3.CONTAMINATED),
          other: fraction.types.includes(FracionTypesV3.OTHER),
          avgPrice: fraction.avgPrice ? `${ fraction.avgPrice.toString() } ${ this.mixWB('W: DKK / ton') }` : '-',
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Fractions')
    },
    onAddFractionClick() {
      this.showDialog = true
      this.dialogProps.fractionToEdit = null
    },
    onEditFractionClick(fractionID: string): void {
      const fraction = this.fractionsAsArrayV3.find((x: FractionV3) => x.id === fractionID)
      this.dialogProps.fractionToEdit = fraction
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },
})
