
import TypeGroupFormV3 from '@/components/Forms/ResourcesV3/TypeGroupFormV3.vue'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'

export default defineComponent({
  name: 'NewTypeGroupDialogV3',
  props: {
    typeGroupToEdit: {
      type: Object as PropType<TypeV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    TypeGroupFormV3,
  },

})
