
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import TypeForm from '@/components/Forms/ResourcesV2/TypeFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ITypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import NewCategoryTypeDialog from '@/components/Modals/Dialogs/ResourcesV2/NewCategoryTypeDialogV2.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import { ICategoryTypeV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeV2'
import ResourceChangeLogV2 from '@/components/ResourceChangeLogV2.vue'

export default defineComponent({
  name: 'TypeEditV2',
  data() {
    return {
      categoryTypeColumns: [
        {
          key: 'category',
          title: this.mixWB('W: Kategori'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewCategoryTypeDialog: shallowRef(NewCategoryTypeDialog),
    }
  },
  computed: {
    ...mapGetters([
      'typesAsArrayV2',
    ]),
    typeToEdit(): ITypeV2 {
      const { typeID } = this.$route.params

      return this.typesAsArrayV2.find((x: ITypeV2) => x.id === typeID)
    },
    categoryTypeRows(): Row[] {
      const categoryTypes = this.typeToEdit.getCategoryTypes()

      return categoryTypes.reduce((prev: Row[], categoryType: ICategoryTypeV2) => {
        prev.push({
          id: categoryType.id,
          category: categoryType.getCategoryTitle(),
          categoryID: categoryType.categoryID,
          title: categoryType.getTitle(),
        })
        return prev
      }, [])
    },
  },
  methods: {
    onAddCategoryTypeClick() {
      this.showDialog = true
    },
    onEditCategoryTypeClick(categoryTypeID: string): void {
      this.$router.push({ name: 'CategoryTypeEditV2', params: { categoryTypeID } })
    },
  },
  components: {
    Page,
    PageTop,
    TypeForm,
    DataTable,
    Edit,
    Button,
    Dialog,
    ResourceChangeLogV2,
  },

})
