
import DataTable, { Column, Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Toggle from '@/components/Form/Toggle.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewTranslationDialog from '@/components/Modals/Dialogs/NewTranslationDialog.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { CategoryV3 } from '@/globals/javascript/models/resources-version-3/CategoryV3'
import { CoatingV3 } from '@/globals/javascript/models/resources-version-3/CoatingV3'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import { RoomV3 } from '@/globals/javascript/models/resources-version-3/RoomV3'
import { TagV3 } from '@/globals/javascript/models/resources-version-3/TagV3'
import { TypeGroupV3 } from '@/globals/javascript/models/resources-version-3/TypeGroupV3'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import {
  IAnalysisTestGroupV3,
  IAnalysisTestV3,
  IColorV3,
  IEWCCodeV3,
  IProjectOptionV3,
  IProjectTypeV3,
  ISampleTypeV3,
} from '@/types/resources-version-3'
import { ILanguage, ITranslationEditItem } from '@/types/translations'
import { sortBy } from 'lodash-es'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'RescourceTranslationsV3',
  data() {
    return {
      // Area
      areas: [
        { id: 'showFractions', title: 'Fraktioner', isSelected: false },
        { id: 'showRawMaterials', title: 'Råmaterialer', isSelected: false },
        { id: 'showMaterials', title: 'Produkter', isSelected: false },
        { id: 'showProductGroups', title: 'Produktgrupper', isSelected: false },
        { id: 'showCategories', title: 'Kategorier', isSelected: false },
        { id: 'showCoatings', title: 'Overfladebehandling', isSelected: false },
        { id: 'showColors', title: 'Farver', isSelected: false },
        { id: 'showRooms', title: 'Rum', isSelected: false },
        { id: 'showTags', title: 'Tags', isSelected: false },
        { id: 'showQuestions', title: 'Spørgsmål', isSelected: false },
        { id: 'showAnalyses', title: 'Analyser', isSelected: false },
        { id: 'showAnalysisTestGroups', title: 'Analyse test grupper', isSelected: false },
        { id: 'showAnalysisTests', title: 'Analyse tests', isSelected: false },
        { id: 'showEWCCodes', title: 'EAK koder', isSelected: false },
        { id: 'showProjectTypes', title: 'Projekttyper', isSelected: false },
        { id: 'showProjectOptions', title: 'Projekt tilvalg', isSelected: false },
        { id: 'showRandom', title: 'Random', isSelected: false },
      ],

      // Dialog
      showDialog: false,
      translationToEdit: undefined as ITranslationEditItem | undefined,
      NewTranslationDialog: shallowRef(NewTranslationDialog),
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'translations',

      // Resources
      'fractionsAsArrayV3',
      'materialsAsArrayV3',
      'typesAsArrayV3',
      'typeGroupsAsArrayV3',
      'categoriesAsArrayV3',
      'coatingsAsArrayV3',
      'colorsAsArrayV3',
      'roomsAsArrayV3',
      'tagsAsArrayV3',
      'questionsAsArrayV3',
      'sampleTypesAsArrayV3',
      'analysisTestGroupsAsArrayV3',
      'analysisTestsAsArrayV3',
      'ewcCodesAsArrayV3',
      'projectTypesAsArrayV3',
      'projectOptionsAsArrayV3',
    ]),
    columns(): Column[] {
      const columns : Column[] = [
        {
          key: 'area',
          title: 'Område',
          canSort: true,
        },
        {
          key: 'key',
          title: 'Key',
          canSort: true,
        },
      ]

      this.languages.forEach((lang: ILanguage) => {
        columns.push({
          key: lang.countryCode,
          title: `${ lang.countryCode } text`,
          canSort: true,
        })
      })

      columns.push({
        key: 'edit',
        title: '',
      })

      return columns
    },
    rows(): Row[] {
      const rows : Row[] = []

      const areasAsObject = this.areas.reduce((prev: any, item) => {
        prev[item.id] = item.isSelected
        return prev
      }, {})

      // Fractions
      if (areasAsObject.showFractions) {
        sortBy(this.fractionsAsArrayV3, ['translation']).forEach((item: FractionV3) => {
          const row: Row = {
            area: 'Fraktioner',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Raw materials
      if (areasAsObject.showRawMaterials) {
        sortBy(this.materialsAsArrayV3, ['translation']).forEach((item: MaterialV3) => {
          const row: Row = {
            area: 'Råmaterialer',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Products
      if (areasAsObject.showMaterials) {
        sortBy(this.typesAsArrayV3, ['translation']).forEach((item: TypeV3) => {
          const row: Row = {
            area: 'Produkter',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Product groups
      if (areasAsObject.showProductGroups) {
        sortBy(this.typeGroupsAsArrayV3, ['translation']).forEach((item: TypeGroupV3) => {
          const row: Row = {
            area: 'Produktgrupper',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Categories
      if (areasAsObject.showCategories) {
        sortBy(this.categoriesAsArrayV3, ['translation']).forEach((item: CategoryV3) => {
          const row: Row = {
            area: 'Kateorier',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Coatings
      if (areasAsObject.showCoatings) {
        sortBy(this.coatingsAsArrayV3, ['translation']).forEach((item: CoatingV3) => {
          const row: Row = {
            area: 'Overfladebehandling',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Colors
      if (areasAsObject.showColors) {
        sortBy(this.colorsAsArrayV3, ['translation']).forEach((item: IColorV3) => {
          const row: Row = {
            area: 'Farver',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Rooms
      if (areasAsObject.showRooms) {
        sortBy(this.roomsAsArrayV3, ['translation']).forEach((item: RoomV3) => {
          // Room name
          const row: Row = {
            area: 'Rum',
            key: item.translation,
          }

          if (!item.translation.includes('W: ')) {
            Object.keys(this.translations[item.translation]).forEach((locale) => {
              const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
              row[lang.countryCode] = this.translations[item.translation][lang.locale]
            })
          }

          rows.push(row)

          // Room suggestion text
          const row2: Row = {
            area: 'Rum - Forslagstekst',
            key: item.suggestionText,
          }

          if (!item.suggestionText.includes('W: ')) {
            Object.keys(this.translations[item.suggestionText]).forEach((locale) => {
              const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
              row2[lang.countryCode] = this.translations[item.suggestionText][lang.locale]
            })
          }

          rows.push(row2)
        })
      }

      // Tags
      if (areasAsObject.showTags) {
        sortBy(this.tagsAsArrayV3, ['translation']).forEach((item: TagV3) => {
          const row: Row = {
            area: 'Tags',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Questions
      if (areasAsObject.showQuestions) {
        sortBy(this.questionsAsArrayV3, ['translation']).forEach((item: TagV3) => {
          if (!item.translation) {
            return
          }

          const row: Row = {
            area: 'Spørgsmål',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Analyses
      if (areasAsObject.showAnalyses) {
        sortBy(this.sampleTypesAsArrayV3, ['translation']).forEach((item: ISampleTypeV3) => {
          const row: Row = {
            area: 'Analyser',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Analysis test groups
      if (areasAsObject.showAnalysisTestGroups) {
        sortBy(this.analysisTestGroupsAsArrayV3, ['translation'])
          .forEach((item: IAnalysisTestGroupV3) => {
            const row: Row = {
              area: 'Analyse test grupper',
              key: item.translation,
            }

            // Checks for each language if its set for the translation
            Object.keys(this.translations[item.translation]).forEach((locale) => {
              const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
              row[lang.countryCode] = this.translations[item.translation][lang.locale]
            })

            rows.push(row)
          })
      }

      // Analysis tests
      if (areasAsObject.showAnalysisTests) {
        sortBy(this.analysisTestsAsArrayV3, ['translation']).forEach((item: IAnalysisTestV3) => {
          const row: Row = {
            area: 'Analyse tests',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // EWC codes
      if (areasAsObject.showEWCCodes) {
        sortBy(this.ewcCodesAsArrayV3, ['translation']).forEach((item: IEWCCodeV3) => {
          const row: Row = {
            area: 'EAK koder',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Project types
      if (areasAsObject.showProjectTypes) {
        sortBy(this.projectTypesAsArrayV3, ['translation']).forEach((item: IProjectTypeV3) => {
          const row: Row = {
            area: 'Projekttyper',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          if (!item.translation.includes('W: ')) {
            Object.keys(this.translations[item.translation]).forEach((locale) => {
              const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
              row[lang.countryCode] = this.translations[item.translation][lang.locale]
            })
          }

          rows.push(row)
        })
      }

      // Project options
      if (areasAsObject.showProjectOptions) {
        sortBy(this.projectOptionsAsArrayV3, ['translation']).forEach((item: IProjectOptionV3) => {
          const row: Row = {
            area: 'Projekt tilvalg',
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      // Random
      if (areasAsObject.showRandom) {
        const items = [
          {
            translation: 'FROM_BEFORE_X',
            area: 'Spørgsmål',
          },
          {
            translation: 'BEFORE_X',
            area: 'Spørgsmål',
          },
          {
            translation: '1_DAY_X_O_CLOCK',
            area: 'Random',
          },
          {
            translation: 'X_DAYS_X_O_CLOCK',
            area: 'Random',
          },
        ]

        sortBy(items, ['translation']).forEach((item) => {
          const row: Row = {
            area: item.area,
            key: item.translation,
          }

          // Checks for each language if its set for the translation
          Object.keys(this.translations[item.translation]).forEach((locale) => {
            const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
            row[lang.countryCode] = this.translations[item.translation][lang.locale]
          })

          rows.push(row)
        })
      }

      return rows
    },
  },
  methods: {
    onSelectAllClick() {
      this.areas.forEach((item) => {
        item.isSelected = true
      })
    },
    onDeselecAllClick() {
      this.areas.forEach((item) => {
        item.isSelected = false
      })
    },
    onEditTranslationClick(key: string) {
      const translation = this.translations[key]

      if (!translation) {
        return
      }

      this.translationToEdit = {
        key,
        translations: Object.keys(translation).map((locale) => ({
          id: locale,
          label: this.languages.find((x: ILanguage) => x.locale === locale).countryCode,
          text: translation[locale],
        })),
      }
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    DataTable,
    Edit,
    Dialog,
    Toggle,
    Button,
  },
})
