
import { defineComponent, PropType } from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Toggle from '@/components/Form/Toggle.vue'
import { mapGetters } from 'vuex'
import { IAreaV2 } from '@/types/resources-version-2'
import { SelectOption } from '@/types/general'
import { CategoryV2, ICategoryV2 } from '@/globals/javascript/models/resources-version-2/CategoryV2'
import { sortBy } from 'lodash-es'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import Divider from '@/components/Form/Divider.vue'
import Button from '@/components/Form/Button.vue'
import Select from '@/components/Form/Select.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'

export default defineComponent({
  name: 'CategoryForm',
  emits: ['submitted'],
  props: {
    categoryToEdit: {
      type: Object as PropType<ICategoryV2>,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      category: {} as ICategoryV2,
      translationError: '',
    }
  },
  computed: {
    ...mapGetters([
      'areasAsArrayV2',
      'nextCategoryIDV2',
      'categoriesAsArrayV2',
      'materialsAsArrayV2',
      'typesAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.categoryToEdit
    },
    isNewInArea(): boolean {
      return this.isNew || this.categoryToEdit?.areaID !== this.category.areaID
    },
    areaOptions() {
      const options: SelectOption[] = []

      this.areasAsArrayV2.forEach((area: IAreaV2) => {
        options.push({
          key: area.id,
          value: area.translation,
        })
      })

      return options
    },
    categoriesForArea(): ICategoryV2[] {
      if (!this.category.areaID) {
        return []
      }

      const categoriesForArea: ICategoryV2[] = []

      this.categoriesAsArrayV2.forEach((category: ICategoryV2) => {
        if (category.areaID === this.category.areaID) {
          categoriesForArea.push(category)
        }
      })

      return sortBy(categoriesForArea, 'position')
    },
    maxPosition(): number {
      return this.isNewInArea
        ? this.categoriesForArea.length + 1
        : this.categoriesForArea.length
    },
  },
  methods: {
    setDataOnLoad() {
      // Edit
      if (this.categoryToEdit) {
        this.category = new CategoryV2(copyObject(this.categoryToEdit))
        return
      }

      // New
      this.category = CategoryV2.newInstance(this.nextCategoryIDV2)
    },
    onAreaChange() {
      if (this.isNewInArea) {
        this.category.position = this.maxPosition
        return
      }

      this.category.position = this.categoryToEdit?.position || this.maxPosition
    },
    onPositionBlur(value: number) {
      if (value < 1) {
        this.category.position = 1
      }
      if (value > this.maxPosition) {
        this.category.position = this.maxPosition
      }
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.category.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && this.isNew
        && doesListAlreadyIncludeItem(this.category.translation, this.categoriesAsArrayV2)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Find categories that needs to update their position
      let categoriesToGetNewPosition: ICategoryV2[] = []
      if (this.isNewInArea) {
        categoriesToGetNewPosition = this.categoriesForArea.filter(
          (x: ICategoryV2) => x.position >= this.category?.position,
        )
      }
      else if (this.categoryToEdit) {
        // New position is below old position
        if (this.category.position < this.categoryToEdit?.position) {
          categoriesToGetNewPosition = this.categoriesForArea.filter(
            (x: ICategoryV2) => x.position >= this.category.position
              && this.categoryToEdit?.position !== undefined
              && x.position < this.categoryToEdit?.position,
          )
        }
        // New position is above old position
        if (this.category.position > this.categoryToEdit?.position) {
          categoriesToGetNewPosition = this.categoriesForArea.filter(
            (x: ICategoryV2) => x.position <= this.category.position
              && this.categoryToEdit?.position !== undefined
              && x.position > this.categoryToEdit?.position,
          )
        }
      }

      // Make new list with updated positions
      const newCategoryList: ICategoryV2[] = []
      this.categoriesAsArrayV2.forEach((category: ICategoryV2) => {
        // Don't include edited type
        if (this.categoryToEdit?.id === category.id) {
          return
        }

        const newCategory = { ...category }
        const needToUpdatePosition = !!categoriesToGetNewPosition.find(
          (x: ICategoryV2) => x.id === category.id,
        )

        // Update position
        if (needToUpdatePosition) {
          // New position is above old position
          if (
            this.categoryToEdit?.position
            && this.category.position > this.categoryToEdit?.position
          ) {
            newCategory.position += -1
          }
          // Otherwise
          else {
            newCategory.position += 1
          }
        }

        newCategoryList.push(newCategory)
      })

      // Add current category
      newCategoryList.push({ ...this.category })

      // Update all positions for all areas
      const sortedList = sortBy(newCategoryList, 'areaID', 'position')
      let lastAreaID = ''
      let index = 1
      sortedList.forEach((category: ICategoryV2) => {
        if (category.areaID !== lastAreaID) {
          index = 1
          lastAreaID = category.areaID || ''
        }

        category.position = index
        index += 1
      })

      // Save new category list
      this.$store.dispatch('setResourceV2', {
        doc: '-categories-',
        data: sortBy(newCategoryList, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    Toggle,
    Button,
    Select,
    TranslationSelect,
    Divider,
    SearchSelect,
  },
  created() {
    this.setDataOnLoad()
  },
})
