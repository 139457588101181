
import Button from '@/components/Form/Button.vue'
import Divider from '@/components/Form/Divider.vue'
import InputField from '@/components/Form/InputField.vue'
import QuestionSelectV3 from '@/components/Form/QuestionSelect/QuestionSelectV3.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'
import { MaterialV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import { TypeV3 } from '@/globals/javascript/models/resources-version-3/TypeV3'
import {
  copyObject, doesListAlreadyIncludeItem, formatEWCCode, updateLog,
} from '@/globals/javascript/utils/helpers'
import { IEWCCodeV3 } from '@/types/resources-version-3'
import { sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TypeFormV3',
  props: {
    typeToEdit: {
      type: Object as PropType<TypeV3>,
      required: false,
    },
  },
  data() {
    return {
      type: {} as TypeV3,
      isLoading: false,
      translationError: '',
      materialError: '',
      fractionsError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextTypeIDV3',
      'materialsAsArrayV3',
      'ewcCodesAsArrayV3',
      'fractionsAsArrayV3',
      'typesAsArrayV3',
      'sampleTypesForSearchSelectAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.typeToEdit
    },
  },
  methods: {
    setTypeOnLoad(): void {
      // Edit
      if (this.typeToEdit) {
        this.type = new TypeV3(copyObject(this.typeToEdit))

        return
      }

      // New
      this.type = new TypeV3({ id: this.nextTypeIDV3 })
      this.type.options.isActive = true
    },
    getMaterialEWCCode(materialID: string, fractionKey: string): string {
      const material: MaterialV3 = this.type.getMaterial()

      if (!material) {
        return ''
      }

      const { ewcID } = material.fractions.fractions[fractionKey]
      const ewcCode: IEWCCodeV3 = this.ewcCodesAsArrayV3.find((x: IEWCCodeV3) => x.id === ewcID)

      return `${ formatEWCCode(ewcCode.id) } - ${ this.mixWB(ewcCode.translation) }`
    },
    getMaterialFraction(materialID: string, fractionKey: string): string {
      const material: MaterialV3 = this.type.getMaterial()

      if (!material) {
        return ''
      }

      const { fractionID } = material.fractions.fractions[fractionKey]
      const fraction: FractionV3 = this.fractionsAsArrayV3.find(
        (x: FractionV3) => x.id === fractionID,
      )

      return this.mixWB(fraction.translation)
    },
    onIsNeverAloneUpdate(status: boolean): void {
      if (!status) {
        this.type.options.isShownAlone = false
      }
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.type.translation) {
        this.translationError = this.mixWB('W: Oversættelse mangler')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(this.type.translation, this.typesAsArrayV3, this.type.id)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Material
      if (allGood && !this.type.materialID) {
        this.materialError = this.mixWB('W: Materiale mangler')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newTypes: TypeV3[] = []
      this.typesAsArrayV3.forEach((type: TypeV3) => {
        if (type.id === this.type.id) {
          return
        }

        newTypes.push(new TypeV3(copyObject(type)))
      })

      // Add new log
      updateLog({
        newItem: this.type,
        oldItem: this.typeToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) type
      newTypes.push(this.type)

      // Save type
      this.$store.dispatch('setResourceV3', {
        doc: '-types-',
        data: sortBy(newTypes, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    SearchSelect,
    Divider,
    Toggle,
    Button,
    QuestionSelectV3,
  },
  created() {
    this.setTypeOnLoad()
  },
})
