
import { defineComponent, PropType } from 'vue'
import Toggle from '@/components/Form/Toggle.vue'
import { IQuestionSelectSuggestion } from '@/components/Form/QuestionSelect/QuestionSelect.vue'

export default defineComponent({
  name: 'QuestionSelectedItem',
  emits: [
    'arrow-click',
    'always-show-toggle',
    'remove-click',
  ],
  props: {
    item: {
      type: Object as PropType<IQuestionSelectSuggestion>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onArrowClick(direction: string): void {
      this.$emit('arrow-click', { index: this.index, direction })
    },
    onAlwaysShowToggle(): void {
      this.$emit('always-show-toggle', this.index)
    },
    onRemoveQuestion(): void {
      this.$emit('remove-click', this.index)
    },
  },
  components: {
    Toggle,
  },
})
