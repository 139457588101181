
import CategoryTypeGroupForm from '@/components/Forms/ResourcesV2/CategoryTypeGroupFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICategoryTypeGroupV2 } from '@/globals/javascript/models/resources-version-2/CategoryTypeGroupV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CategoryTypeGroupEditV2',
  computed: {
    ...mapGetters([
      'categoryTypeGroupsAsArrayV2',
    ]),
    categoryTypeGroupToEdit(): ICategoryTypeGroupV2 {
      const { categoryTypeGroupID } = this.$route.params

      return this.categoryTypeGroupsAsArrayV2.find(
        (x: ICategoryTypeGroupV2) => x.id === categoryTypeGroupID,
      )
    },
  },
  components: {
    Page,
    PageTop,
    CategoryTypeGroupForm,
  },
})
