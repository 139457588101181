
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import { ItemV3 } from '@/globals/javascript/models/resources-version-3/ItemV3'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ItemFormV3',
  props: {
    itemToEdit: {
      type: Object as PropType<ItemV3>,
      required: false,
    },
  },
  data() {
    return {
      item: {} as ItemV3,
      isLoading: false,
      translationError: '',
      itemGroupError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextItemIDV3',
      'itemsAsArrayV3',
      'itemGroupsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.itemToEdit
    },
  },
  methods: {
    setItemOnLoad(): void {
      // Edit
      if (this.itemToEdit) {
        this.item = new ItemV3(copyObject(this.itemToEdit))
        return
      }

      // New
      this.item = new ItemV3({ id: this.nextItemIDV3 })
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.item.translation) {
        this.translationError = this.mixWB('W: Oversættelse mangler')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(this.item.translation, this.itemsAsArrayV3, this.item.id)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Item group
      if (allGood && !this.item.itemGroupID) {
        this.itemGroupError = this.mixWB('W: Gruppe mangler')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newItems: ItemV3[] = []
      this.itemsAsArrayV3.forEach((item: ItemV3) => {
        if (item.id === this.item.id) {
          return
        }

        newItems.push(new ItemV3(copyObject(item)))
      })

      // Add new (or edited) type
      newItems.push(this.item)

      // Save type
      this.$store.dispatch('setResourceV3', {
        doc: '-items-',
        data: sortBy(newItems, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField, TranslationSelect, SearchSelect, Button,
  },
  created() {
    this.setItemOnLoad()
  },
})
