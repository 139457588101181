
import { defineComponent, PropType } from 'vue'
import InputField from '@/components/Form/InputField.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import Button from '@/components/Form/Button.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'
import { copyObject, doesListAlreadyIncludeItem, updateLog } from '@/globals/javascript/utils/helpers'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import { FractionV3 } from '@/globals/javascript/models/resources-version-3/FractionsV3'

export default defineComponent({
  name: 'FractionFormV3',
  props: {
    fractionToEdit: {
      type: Object as PropType<FractionV3>,
      required: false,
    },
  },
  data() {
    return {
      fraction: {} as FractionV3,
      isLoading: false,
      translationError: '',
      typesError: '',
      avgPriceError: '',
      typeOptions: [
        {
          id: 'clean',
          translation: 'CLEAN',
        },
        {
          id: 'contaminated',
          translation: 'CONTAMINATED_2',
        },
        {
          id: 'other',
          translation: 'W: Farlig og asbest',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'fractionsAsArrayV3',
      'nextFractionIDV3',
    ]),
    isNew(): boolean {
      return !this.fractionToEdit
    },
  },
  methods: {
    setDataOnLoad() {
      // Edit
      if (this.fractionToEdit) {
        this.fraction = new FractionV3(copyObject(this.fractionToEdit))
        return
      }

      // New
      this.fraction = new FractionV3({ id: this.nextFractionIDV3 })
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.fraction.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(this.fraction.translation,
          this.fractionsAsArrayV3,
          this.fraction.id)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Types
      if (!this.fraction.types.length) {
        this.typesError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      return allGood
    },
    onSubmit() {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newFractions: FractionV3[] = []
      this.fractionsAsArrayV3.forEach((fraction: FractionV3) => {
        if (fraction.id === this.fraction.id) {
          return
        }

        newFractions.push(new FractionV3({ ...fraction }))
      })

      // Add new log
      updateLog({
        newItem: this.fraction,
        oldItem: this.fractionToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) item
      newFractions.push(this.fraction)

      // Save type
      this.$store.dispatch('setResourceV3', {
        doc: '-fractions-',
        data: sortBy(newFractions, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    Button,
    SearchSelect,
  },
  created() {
    this.setDataOnLoad()
  },
})
