
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import { IEWCCodeV3 } from '@/types/resources-version-3'
import { formatEWCCode } from '@/globals/javascript/utils/helpers'

export default defineComponent({
  name: 'EWCCodesV3',
  data() {
    return {
      columns: [
        {
          key: 'code',
          title: this.mixWB('W: Kode'),
          canSort: true,
        },
        {
          key: 'description',
          title: this.mixWB('W: Beskrivelse'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'ewcCodesAsArrayV3',
      'currentUser',
    ]),
    rows(): Row[] {
      const rows: Row[] = []
      this.ewcCodesAsArrayV3.forEach((ewcCode: IEWCCodeV3) => {
        rows.push({
          id: ewcCode.id,
          code: formatEWCCode(ewcCode.id),
          description: this.mixWB(ewcCode.translation),
        })
      })
      return rows
    },
  },
  methods: {
    onTransferV2DataClick() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('transferV2EWCCodes')
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
  },
})
