
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import SingleTranslationForm from '@/components/Forms/SingleTranslationForm.vue'
import { ITranslationEditItem } from '@/types/translations'

export default defineComponent({
  name: 'NewTranslationDialog',
  emits: ['close-dialog'],
  props: {
    translationToEdit: {
      type: Object as PropType<ITranslationEditItem>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    SingleTranslationForm,
  },

})
