
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import NewPriceDialogVue from '@/components/Modals/Dialogs/ResourcesV3/NewPriceDialog.vue'
import Edit from '@/components/DataTable/Edit.vue'
import { IAnalysisDeliveryTimeOptionV3, ISampleTypeV3 } from '@/types/resources-version-3'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import { mixWB } from '@/globals/javascript/utils/mixins'
import Dialog from '@/components/Modals/Dialog.vue'
import { IPricesProItem, UPricesItem } from '@/types/prices'

interface IRowMapperItem extends Row {
  id: string | number
  title: string | number
  sampler: string | number
  samplerPlus: string | number
  categoryKey: string
}
export default defineComponent({
  name: 'Prices',
  data() {
    return {
      // Dialog
      showDialog: false,
      dialogProps: {
        priceItemCategoryTitle: '',
        priceItemTitle: '',
        priceItemID: '',
        priceCategoryKey: '',
      },
      NewPriceDialog: shallowRef(NewPriceDialogVue),
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'sampler',
          title: this.mixWB('W: Sampler'),
          canSort: true,
        },
        {
          key: 'samplerPlus',
          title: this.mixWB('W: Sampler +'),
          canSort: true,
        },
        {
          key: 'eurofins',
          title: this.mixWB('W: Eurofins'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],

      columnsSingle: [
        {
          key: 'sampler',
          title: this.mixWB('W: Sampler'),
          canSort: true,
        },
        {
          key: 'samplerPlus',
          title: this.mixWB('W: Sampler +'),
          canSort: true,
        },
        {
          key: 'eurofins',
          title: this.mixWB('W: Eurofins'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],

    }
  },
  computed: {
    ...mapGetters([
      'pricesProAsArray',
      'pricesLabsAsArray',
      'analysisDeliveryTimeOptionsAsArrayV3',
      'sampleTypesAsArrayV3',
    ]),
    rows() {
      const pricesArray = [...this.pricesProAsArray, ...this.pricesLabsAsArray] as UPricesItem[]
      const mappedData = pricesArray
        .reduce((prev, priceItem: UPricesItem) => {
        // analysisDeliveryTime
          Object.keys(priceItem.analysisDeliveryTime).forEach((id) => {
            const { value } = priceItem.analysisDeliveryTime[id]
            const deliveryItem:
            IAnalysisDeliveryTimeOptionV3 = this.analysisDeliveryTimeOptionsAsArrayV3
              .find((a: IAnalysisDeliveryTimeOptionV3) => a.id === id)

            let title = ''
            if (deliveryItem.days === 1) {
              title = this.mixWB('1_DAY_X_O_CLOCK', [deliveryItem.hours])
            }
            else {
              title = this.mixWB('X_DAYS_X_O_CLOCK', [deliveryItem.days, deliveryItem.hours])
            }

            const existingObj = prev.analysisDeliveryTime.find((o) => o.id === id)
            const objToUse = existingObj ?? {
              id,
              title,
              sampler: '-',
              samplerPlus: '-',
              eurofins: '-',
              categoryKey: 'analysisDeliveryTime',
            }

            if (!existingObj) {
              prev.analysisDeliveryTime.push(objToUse)
            }
            if (priceItem.id === 'sampler') {
              objToUse.sampler = `${ value * 100 } %`
            }
            if (priceItem.id === 'samplerPlus') {
              objToUse.samplerPlus = `${ value * 100 } %`
            }
            if (priceItem.id === 'eurofins') {
              objToUse.eurofins = `${ value * 100 } %`
            }
          })

          // matAnalyses
          Object.keys(priceItem.matAnalyses).forEach((id) => {
            const { value } = priceItem.matAnalyses[id]
            const existingObj = prev.matAnalyses.find((o) => o.id === id)

            const sampleTypeItem: ISampleTypeV3 = this.sampleTypesAsArrayV3
              .find((a: ISampleTypeV3) => a.id === id)

            const objToUse = existingObj ?? {
              id,
              title: this.mixWB(sampleTypeItem.translation),
              sampler: '-',
              samplerPlus: '-',
              eurofins: '-',
              categoryKey: 'matAnalyses',
            }

            if (!existingObj) {
              prev.matAnalyses.push(objToUse)
            }
            if (priceItem.id === 'sampler') {
              objToUse.sampler = `${ value } Kr.`
            }
            if (priceItem.id === 'samplerPlus') {
              objToUse.samplerPlus = `${ value } Kr.`
            }
            if (priceItem.id === 'eurofins') {
              objToUse.eurofins = `${ value } Kr.`
            }
          })

          // projectFee
          if ((priceItem as IPricesProItem)?.other?.projectFee && priceItem.id === 'sampler') {
            prev.projectFee.sampler = `${ (priceItem as IPricesProItem).other.projectFee.value } Kr.`
          }
          if ((priceItem as IPricesProItem)?.other?.projectFee && priceItem.id === 'samplerPlus') {
            prev.projectFee.samplerPlus = `${ (priceItem as IPricesProItem).other.projectFee.value } Kr.`
          }
          if ((priceItem as IPricesProItem)?.other?.projectFee && (priceItem.id === 'samplerPlus' || priceItem.id === 'sampler')) {
            prev.projectFee.id = 'projectFee'
            prev.projectFee.categoryKey = 'other'
          }

          // sampleHandOver
          Object.keys(priceItem.sampleHandOver).forEach((id) => {
            const { value } = priceItem.sampleHandOver[id]

            const existingObj = prev.sampleHandOver.find((o) => o.id === id)

            const titleMapper: {[key:string]: string} = {
              'drop-off-point': mixWB('W: Droppoint'),
              'custom-address': mixWB('W: Egen adresse (gratis ved bestilling af over 5 analyser)'),
              'pick-up-point': mixWB('W: Pickup point'),
            }
            const objToUse = existingObj ?? {
              id,
              title: titleMapper[id],
              eurofins: '-',
              sampler: '-',
              samplerPlus: '-',
              categoryKey: 'sampleHandOver',
            }

            if (!existingObj) {
              prev.sampleHandOver.push(objToUse)
            }
            if (priceItem.id === 'sampler') {
              objToUse.sampler = `${ value } Kr.`
            }
            if (priceItem.id === 'samplerPlus') {
              objToUse.samplerPlus = `${ value } Kr.`
            }
            if (priceItem.id === 'eurofins') {
              objToUse.eurofins = `${ value } Kr.`
            }
          })

          return prev
        },
      {
        analysisDeliveryTime: [],
        matAnalyses: [],
        projectFee: {
          title: mixWB('W: Projekt pris'),
          id: '',
          eurofins: '-',
          sampler: '-',
          samplerPlus: '-',
          categoryKey: 'projectFee',
        },
        sampleHandOver: [],
      } as {
        analysisDeliveryTime: IRowMapperItem[],
        matAnalyses: IRowMapperItem[],
        projectFee: IRowMapperItem,
        sampleHandOver: IRowMapperItem[]
      })

      return mappedData
    },
  },
  methods: {
    onEditPriceClick(categoryTitle:string, title:string, categoryKey: string, id:string): void {
      // const fraction = this.pricesProAsArray.find((x: IPricesItemV3) => x.id === price)
      this.dialogProps.priceItemCategoryTitle = categoryTitle
      this.dialogProps.priceItemTitle = title
      this.dialogProps.priceCategoryKey = categoryKey
      this.dialogProps.priceItemID = id
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    DataTable,
    Edit,
    Dialog,
  },
})
