
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewTagDialogVue from '@/components/Modals/Dialogs/ResourcesV2/NewTagDialogV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ITagV2 } from '@/globals/javascript/models/resources-version-2/TagV2'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'TagsV2',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewTagDialog: shallowRef(NewTagDialogVue),
    }
  },
  computed: {
    ...mapGetters([
      'tagsAsArrayV2',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.tagsAsArrayV2.forEach((tag: ITagV2) => {
        rows.push({
          id: tag.id,
          title: this.mixWB(tag.translation),
        })
      })

      return rows
    },
  },
  methods: {
    onAddTagClick() {
      this.showDialog = true
    },
    onEditTagClick(tagID: string) {
      this.$router.push({ name: 'TagEditV2', params: { tagID } })
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Dialog,
    Edit,
  },

})
