
import { defineComponent, shallowRef } from 'vue'
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import NewTypeDialog from '@/components/Modals/Dialogs/ResourcesV2/NewTypeDialogV2.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import { ITypeV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { mapGetters } from 'vuex'
import Edit from '@/components/DataTable/Edit.vue'

export default defineComponent({
  name: 'TypesV2',
  data() {
    return {
      columns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'noOfCategories',
          title: this.mixWB('W: Kategorier'),
          canSort: true,
        },
        {
          key: 'noOfQuestions',
          title: this.mixWB('W: Spørgsmål'),
          canSort: true,
        },
        {
          key: 'isNeverAlone',
          title: this.mixWB('W: Er aldrig alene?'),
          canSort: true,
        },
        {
          key: 'isShownAlone',
          title: this.mixWB('W: Vises for sig?'),
          canSort: true,
        },
        {
          key: 'isNotRecyclable',
          title: this.mixWB('W: Kan ikke genanvendes?'),
          canSort: true,
        },
        {
          key: 'materials',
          title: this.mixWB('W: Materialer'),
          canSort: true,
        },
        {
          key: 'wdgID',
          title: this.mixWB('WORK_DESCRIPTION_GROUP'),
          canSort: true,
        },
        {
          key: 'user',
          title: this.mixWB('W: Bruger'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      // Dialog
      showDialog: false,
      NewTypeDialog: shallowRef(NewTypeDialog),
    }
  },
  computed: {
    ...mapGetters([
      'typesAsArrayV2',
      'workDescriptionGroupsAsArrayV2',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.typesAsArrayV2.forEach((type: ITypeV2) => {
        const user = type.getUser()
        const wdgItem = this.workDescriptionGroupsAsArrayV2.find(
          (x: ITypeV2) => x.id === type.wdgID,
        )
        const wdgID = wdgItem ? `${ type.wdgID } - ${ this.mixWB(wdgItem.translation) }` : '-'
        rows.push({
          title: this.mixWB(type.translation),
          id: type.id,
          noOfCategories: type.getCategoryTypes().length.toString(),
          noOfQuestions: type.questions.length.toString(),
          isNeverAlone: type.options.isNeverAlone,
          isShownAlone: type.options.isShownAlone,
          isNotRecyclable: type.options.isNotRecyclable,
          materials: type.getMaterials(),
          wdgID,
          user: user ? user.fullName : '',
        })
      })

      return rows
    },
  },
  methods: {
    onAddTypeClick(): void {
      this.showDialog = true
    },
    onEditTypeClick(typeID: string): void {
      this.$router.push({ name: 'TypeEditV2', params: { typeID } })
    },
  },
  components: {
    Page,
    DataTable,
    PageTop,
    Button,
    Dialog,
    Edit,
  },

})
