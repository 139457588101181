
import RoomFormV3 from '@/components/Forms/ResourcesV3/RoomFormV3.vue'
import { RoomV3 } from '@/globals/javascript/models/resources-version-3/RoomV3'
import { defineComponent, PropType } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'

export default defineComponent({
  name: 'NewRoomDialogV3',
  props: {
    roomToEdit: {
      type: Object as PropType<RoomV3>,
      required: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    RoomFormV3,
  },

})
