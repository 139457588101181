
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { IProjectOptionV3, IProjectTypeV3 } from '@/types/resources-version-3'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ProjectTypesV3',
  data() {
    return {
      projectTypeColumns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('ID'),
          canSort: true,
        },
        {
          key: 'kindOfMapping',
          title: this.mixWB('W: Slags'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
      projectOptionColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('ID'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'projectTypesAsArrayV3',
      'projectOptionsAsArrayV3',
      'currentUser',
    ]),
    projectTypeRows(): Row[] {
      const rows: Row[] = []
      this.projectTypesAsArrayV3.forEach((projectType: IProjectTypeV3) => {
        rows.push({
          position: projectType.position,
          title: this.mixWB(projectType.translation),
          id: projectType.id,
          kindOfMapping: projectType.options.isScreening
            ? this.mixWB('SCREENING')
            : this.mixWB('MAPPING'),
          isActive: projectType.options.isActive,
        })
      })
      return rows
    },
    projectOptionRows(): Row[] {
      const rows: Row[] = []
      this.projectOptionsAsArrayV3.forEach((projectOption: IProjectOptionV3) => {
        rows.push({
          title: this.mixWB(projectOption.translation),
          id: projectOption.id,
          isActive: projectOption.options.isActive,
        })
      })
      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3ProjectTypes')
      this.$store.dispatch('uploadV3ProjectOptions')
    },
  },
  components: {
    Page, PageTop, Button, DataTable,
  },
})
