
import { ITagV2, TagV2 } from '@/globals/javascript/models/resources-version-2/TagV2'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'

export default defineComponent({
  name: 'TagForm',
  props: {
    tagToEdit: {
      type: Object as PropType<ITagV2>,
      required: false,
    },
  },
  data() {
    return {
      tag: {} as ITagV2,
      isLoading: false,
      typeOptions: [
        {
          key: 'default',
          value: 'default',
        },
        {
          key: 'year',
          value: 'year',
        },
      ],
      translationError: '',
      yearError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextTagIDV2',
      'tagsAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.tagToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.tagToEdit) {
        this.tag = new TagV2(copyObject(this.tagToEdit))
        return
      }

      // New
      this.tag = TagV2.newInstance(this.nextTagIDV2)
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.tag.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && this.isNew
        && doesListAlreadyIncludeItem(this.tag.translation, this.tagsAsArrayV2)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newTags: ITagV2[] = []
      this.tagsAsArrayV2.forEach((tag: ITagV2) => {
        if (tag.id === this.tag.id) {
          return
        }

        newTags.push({ ...tag })
      })

      // Add new (or edited) tag
      newTags.push({ ...this.tag })

      // Save tag
      this.$store.dispatch('setResourceV2', {
        doc: '-tags-',
        data: sortBy(newTags, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    Button,
    TranslationSelect,
  },
  created() {
    this.setDataOnLoad()
  },
})
