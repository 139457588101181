
import { formatEWCCode } from '@/globals/javascript/utils/helpers'
import { ObjPairString } from '@/types/general'
import { IEWCCodeV3 } from '@/types/resources-version-3'
import { sortBy } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

interface ISuggestion {
  id: string
  title: string
  isGroupTitle?: boolean
}

export default defineComponent({
  name: 'EWCSelectV3',
  props: {
    name: {
      type: String,
      required: false,
    },
    data: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
      groupTitles: {
        1701: this.mixWB('W: Beton, mursten, tegl og keramik'),
        1702: this.mixWB('W: Træ, glas og plast'),
        1703: this.mixWB('W: Bitumenholdige blandinger, kultjære og tjærede produkter'),
        1704: this.mixWB('W: Metaller (og legeringer heraf)'),
        1705: this.mixWB('W: Jord (herunder opgravet jord fra forurenede grunde), sten og klapmaterialer'),
        1706: this.mixWB('W: Isolationsmateriale og asbestholdige byggematerialer'),
        1708: this.mixWB('W: Gipsbaserede byggematerialer'),
        1709: this.mixWB('W: Andet bygnings- og nedrivningsaffald'),
        2001: this.mixWB('W: Separat indsamlede fraktioner (med undtagelse af 15 01)'),
      } as ObjPairString,
    }
  },
  computed: {
    ...mapGetters([
      'ewcCodesAsArrayV3',
    ]),
    selectedItems(): ObjPairString[] {
      if (!this.data) {
        return []
      }

      const item: IEWCCodeV3 = this.ewcCodesAsArrayV3.find((x: IEWCCodeV3) => x.id === this.data)

      return [
        {
          title: `${ formatEWCCode(item.id) } - ${ this.mixWB(item.translation) }`,
        },
      ]
    },
    suggestions(): ISuggestion[] {
      const suggestions: ISuggestion[] = []
      const searchTerm = this.searchTerm.toLowerCase()

      // No search term
      if (!this.searchTerm) {
        this.ewcCodesAsArrayV3.forEach((ewcCode: IEWCCodeV3) => {
          suggestions.push({
            id: ewcCode.id,
            title: `${ formatEWCCode(ewcCode.id) } - ${ this.mixWB(ewcCode.translation) }`,
          })
        })
      }

      // With search term
      else {
        this.ewcCodesAsArrayV3.forEach((ewcCode: IEWCCodeV3) => {
          const text = this.mixWB(ewcCode.translation).toLowerCase()
          if (text.includes(searchTerm)) {
            suggestions.push({
              id: ewcCode.id,
              title: `${ formatEWCCode(ewcCode.id) } - ${ this.mixWB(ewcCode.translation) }`,
            })
          }
        })
      }

      const sortedSuggestions = sortBy(suggestions, 'id')

      // Return suggestions with group titles added
      let lastID = ''
      return sortedSuggestions.reduce((prev: ISuggestion[], item: ISuggestion) => {
        const currentID = item.id.split('').splice(0, 4).join('')
        if (lastID !== currentID) {
          prev.push({
            id: currentID,
            title: this.groupTitles[currentID],
            isGroupTitle: true,
          })
          lastID = currentID
        }
        prev.push(item)
        return prev
      }, [])
    },
  },
  methods: {
    onSearchTermInput(): void {
      this.showSuggestions = true
    },
    onRemoveItem(): void {
      this.$emit('update:data', '')
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion: ISuggestion): void {
      this.$emit('update:data', suggestion.id)
      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
})
