
import DataTableVue, { Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import { ItemV3 } from '@/globals/javascript/models/resources-version-3/ItemV3'
import { IItemGroupV3 } from '@/types/resources-version-3'
import Edit from '@/components/DataTable/Edit.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NewItemDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewItemDialogV3.vue'

export default defineComponent({
  name: 'ItemsV3',
  data() {
    return {
      itemsColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'itemGroupID',
          title: this.mixWB('W: Gruppe'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      itemGroupsColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'count',
          title: this.mixWB('W: Antal genstande'),
          canSort: true,
        },
      ],

      // Dialog
      showDialog: false,
      dialogProps: {
        itemToEdit: null,
      },
      NewItemDialogV3: shallowRef(NewItemDialogV3),
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'itemsAsArrayV3',
      'itemGroupsAsArrayV3',
    ]),
    itemsRows(): Row[] {
      const rows: Row[] = []

      this.itemsAsArrayV3.forEach((item: ItemV3) => {
        const itemGroup = this.itemGroupsAsArrayV3.find(
          (x: IItemGroupV3) => x.id === item.itemGroupID,
        )

        rows.push({
          title: this.mixWB(item.translation),
          id: item.id,
          itemGroupID: this.mixWB(itemGroup.translation),
        })
      })

      return rows
    },
    itemGroupsRows(): Row[] {
      const rows: Row[] = []

      this.itemGroupsAsArrayV3.forEach((itemGroup: IItemGroupV3) => {
        rows.push({
          title: this.mixWB(itemGroup.translation),
          id: itemGroup.id,
          count: this.itemsAsArrayV3.filter(
            (x: ItemV3) => x.itemGroupID === itemGroup.id,
          ).length.toString(),
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Items')
      this.$store.dispatch('uploadV3ItemGroups')
    },
    onAddItemClick() {
      this.showDialog = true
      this.dialogProps.itemToEdit = null
    },
    onEditItemClick(itemID: string) {
      const item = this.itemsAsArrayV3.find((x: ItemV3) => x.id === itemID)
      this.dialogProps.itemToEdit = item
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTableVue,
    Edit,
    Dialog,
  },
})
