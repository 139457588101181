
import { defineComponent, PropType } from 'vue'
import {
  TypeV2, ITypeV2, ITypeMaterialDataItemV2,
} from '@/globals/javascript/models/resources-version-2/TypeV2'
import InputField from '@/components/Form/InputField.vue'
import SearchSelect from '@/components/Form/SearchSelect.vue'
import Toggle from '@/components/Form/Toggle.vue'
import Button from '@/components/Form/Button.vue'
import { mapGetters } from 'vuex'
import { IEWCCodeV2, IWDGV2 } from '@/types/resources-version-2'
import {
  copyObject, doesListAlreadyIncludeItem, formatEWCCode, updateLog,
} from '@/globals/javascript/utils/helpers'
import Divider from '@/components/Form/Divider.vue'
import { IMaterialV2, IMaterialFractionV2 } from '@/globals/javascript/models/resources-version-2/MaterialV2'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import EWCSelectV2 from '@/components/Form/EWCSelectV2.vue'
import FractionSelectV2 from '@/components/Form/FractionSelectV2.vue'
import { IFractionV2 } from '@/globals/javascript/models/resources-version-2/FractionV2'
import { sortBy } from 'lodash-es'
import QuestionSelect from '@/components/Form/QuestionSelect/QuestionSelect.vue'

export default defineComponent({
  name: 'TypeForm',
  props: {
    typeToEdit: {
      type: Object as PropType<ITypeV2>,
      required: false,
    },
  },
  data() {
    return {
      type: {} as ITypeV2,
      isLoading: false,
      translationError: '',
      wdgError: '',
      materialError: '',
      amountError: '',
      fractionsError: '',
    }
  },
  computed: {
    ...mapGetters([
      'workDescriptionGroupsAsArrayV2',
      'nextTypeIDV2',
      'typesAsArrayV2',
      'materialsAsArrayV2',
      'ewcCodesAsArrayV2',
      'fractionsAsArrayV2',
      'sampleTypesAsArrayV2',
    ]),
    isNew(): boolean {
      return !this.typeToEdit
    },
    wdgOptions(): IWDGV2[] {
      return this.workDescriptionGroupsAsArrayV2.filter(
        (x: IWDGV2) => !x.options?.isUsedForMultiType,
      )
    },
  },
  methods: {
    setTypeOnLoad(): void {
      // Edit
      if (this.typeToEdit) {
        this.type = new TypeV2(copyObject(this.typeToEdit))
        return
      }

      // New
      this.type = TypeV2.newInstance(this.nextTypeIDV2)
    },
    getMaterialTitle(materialID: string): string {
      const material = this.materialsAsArrayV2.find((x: IMaterialV2) => x.id === materialID)
      return this.mixWB(material.translation)
    },
    getMaterialEWCCode(materialID: string, fractionKey: string): string {
      const material: IMaterialV2 = this.materialsAsArrayV2.find(
        (x: IMaterialV2) => x.id === materialID,
      )
      const { ewcID } = material.fractions.fractions[fractionKey]
      const ewcCode: IEWCCodeV2 = this.ewcCodesAsArrayV2.find((x: IEWCCodeV2) => x.id === ewcID)

      return `${ formatEWCCode(ewcCode.id) } - ${ this.mixWB(ewcCode.translation) }`
    },
    getMaterialFraction(materialID: string, fractionKey: string): string {
      const material: IMaterialV2 = this.materialsAsArrayV2.find(
        (x: IMaterialV2) => x.id === materialID,
      )
      const { fractionID } = material.fractions.fractions[fractionKey]
      const fraction: IFractionV2 = this.fractionsAsArrayV2.find(
        (x: IFractionV2) => x.id === fractionID,
      )

      return this.mixWB(fraction.translation)
    },
    onMaterialUpdate(): void {
      this.materialError = ''
      this.type.materialData = this.type.setMaterialData()
    },
    onIsNeverAloneUpdate(status: boolean): void {
      if (!status) {
        this.type.options.isShownAlone = false
      }
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.type.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && this.isNew
        && doesListAlreadyIncludeItem(this.type.translation, this.typesAsArrayV2)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // WDG
      if (allGood && !this.type.wdgID) {
        this.wdgError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      // Material
      if (allGood && !this.type.materialIDs.length) {
        this.materialError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      // Material data
      if (Object.keys(this.type.materialData).length) {
        // Amounts
        let materialDataAmountsGood = true
        Object.keys(this.type.materialData).forEach((materialID: string) => {
          const materialData: ITypeMaterialDataItemV2 = this.type.materialData[materialID]
          Object.keys(materialData.units).forEach((unitID) => {
            const unit = materialData.units[(unitID as 'm2' | 'meters' | 'pcs')]
            if (!materialDataAmountsGood) {
              return
            }
            if (!unit.isActive) {
              return
            }
            materialDataAmountsGood = Object.keys(unit.fields).every((fieldKey: string) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const field = (unit.fields as any)[fieldKey]

              return field.value > 0
            })
          })
        })

        if (!materialDataAmountsGood) {
          this.amountError = this.mixWB('W: Nogle mængder er ikke udfyldt korrekt')
          allGood = false
        }

        // Fractions
        Object.keys(this.type.materialData).forEach((materialID: string) => {
          const materialData: ITypeMaterialDataItemV2 = this.type.materialData[materialID]
          let materialDataFractionsGood = true
          let oneOrMoreFractionsAreFilled = false
          Object.keys(materialData.fractions.fractions).forEach((fractionKey) => {
            if (!materialDataAmountsGood) {
              return
            }
            const fraction: IMaterialFractionV2 = materialData.fractions.fractions[fractionKey]
            if (
              (fraction.ewcID && !fraction.fractionID)
              || (!fraction.ewcID && fraction.fractionID)
            ) {
              materialDataFractionsGood = false
            }
            if (fraction.ewcID && fraction.fractionID) {
              oneOrMoreFractionsAreFilled = true
            }
          })

          if (!materialDataFractionsGood) {
            this.fractionsError = this.mixWB('W: Nogle påbegyndte fraktioner er ikke helt udfyldt')
            allGood = false
          }

          if (oneOrMoreFractionsAreFilled && !materialData.fractions.source) {
            this.fractionsError = this.mixWB('W: Kilde mangler')
            allGood = false
          }
        })
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newTypes: ITypeV2[] = []
      this.typesAsArrayV2.forEach((type: ITypeV2) => {
        if (type.id === this.type.id) {
          return
        }

        newTypes.push({ ...type })
      })

      // Add new log
      updateLog({
        newItem: this.type,
        oldItem: this.typeToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) type
      newTypes.push({ ...this.type })

      // Save type
      this.$store.dispatch('setResourceV2', {
        doc: '-types-',
        data: sortBy(newTypes, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    SearchSelect,
    TranslationSelect,
    Toggle,
    Button,
    Divider,
    FractionSelectV2,
    EWCSelectV2,
    QuestionSelect,
  },
  created() {
    this.setTypeOnLoad()
  },
})
