
import { defineComponent } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import GroupQuestionForm from '@/components/Forms/ResourcesV2/GroupQuestionFormV2.vue'

export default defineComponent({
  name: 'NewGroupQuestionDialog',
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    GroupQuestionForm,
  },
})
