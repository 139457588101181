
import Button from '@/components/Form/Button.vue'
import Divider from '@/components/Form/Divider.vue'
import InputField from '@/components/Form/InputField.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import { RoomV3 } from '@/globals/javascript/models/resources-version-3/RoomV3'
import { copyObject, doesListAlreadyIncludeItem } from '@/globals/javascript/utils/helpers'
import { sortBy } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'RoomFormV3',
  props: {
    roomToEdit: {
      type: Object as PropType<RoomV3>,
      required: false,
    },
  },
  data() {
    return {
      room: {} as RoomV3,
      isLoading: false,
      translationError: '',
      suggestionError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextRoomIDV3',
      'roomsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.roomToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.roomToEdit) {
        this.room = new RoomV3(copyObject(this.roomToEdit))
        return
      }

      // New
      this.room = new RoomV3({ id: this.nextRoomIDV3 })
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.room.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(this.room.translation, this.roomsAsArrayV3, this.room.id)
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Suggestion
      if (allGood && !this.room.suggestionText) {
        this.suggestionError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newRooms: RoomV3[] = []
      this.roomsAsArrayV3.forEach((room: RoomV3) => {
        if (room.id === this.room.id) {
          return
        }

        newRooms.push({ ...room })
      })

      // Add new (or edited) room
      newRooms.push({ ...this.room })

      // Save room
      this.$store.dispatch('setResourceV3', {
        doc: '-rooms-',
        data: sortBy(newRooms, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    TranslationSelect,
    Divider,
    Toggle,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
})
