
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import GroupQuestionForm from '@/components/Forms/ResourcesV2/GroupQuestionFormV2.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { IGroupQuestionV2 } from '@/globals/javascript/models/resources-version-2/GroupQuestionV2'
import { ITypeV2, ITypeQuestionV2 } from '@/globals/javascript/models/resources-version-2/TypeV2'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'GroupQuestionEditV2',
  data() {
    return {
      typeColumns: [
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'groupQuestionsAsArrayV2',
      'typesAsArrayV2',
    ]),
    groupQuestionToEdit(): IGroupQuestionV2 {
      const { groupQuestionID } = this.$route.params

      return this.groupQuestionsAsArrayV2.find((x: IGroupQuestionV2) => x.id === groupQuestionID)
    },
    typeRows(): Row[] {
      return this.typesAsArrayV2.reduce((prev: Row[], type: ITypeV2) => {
        const isUsingQuestion = type.questions.find(
          (x: ITypeQuestionV2) => x.questionID === this.groupQuestionToEdit.id,
        )
        if (isUsingQuestion) {
          prev.push({
            id: type.id,
            title: this.mixWB(type.translation),
          })
        }

        return prev
      }, [])
    },
  },
  methods: {
    onEditTypeClick(typeID: string): void {
      this.$router.push({ name: 'TypeEditV2', params: { typeID } })
    },
  },
  components: {
    Page,
    PageTop,
    GroupQuestionForm,
    Edit,
    DataTable,
  },
})
