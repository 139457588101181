
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/Form/Button.vue'
import InputField from '@/components/Form/InputField.vue'
import { copyObject, doesListAlreadyIncludeItem, updateLog } from '@/globals/javascript/utils/helpers'
import EWCSelectV3 from '@/components/Form/EWCSelectV3.vue'
import FractionSelectV3 from '@/components/Form/FractionSelectV3.vue'
import Toggle from '@/components/Form/Toggle.vue'
import TranslationSelect from '@/components/Form/TranslationSelect.vue'
import Divider from '@/components/Form/Divider.vue'
import { MaterialV3, IMaterialFractionV3 } from '@/globals/javascript/models/resources-version-3/MaterialV3'
import { sortBy } from 'lodash-es'

export default defineComponent({
  name: 'MaterialFormV3',
  props: {
    materialToEdit: {
      type: Object as PropType<MaterialV3>,
      required: false,
    },
  },
  data() {
    return {
      material: {} as MaterialV3,
      isLoading: false,
      translationError: '',
      co2Error: '',
      densityError: '',
      fractionsError: '',
    }
  },
  computed: {
    ...mapGetters([
      'nextMaterialIDV3',
      'materialsAsArrayV3',
    ]),
    isNew(): boolean {
      return !this.materialToEdit
    },
  },
  methods: {
    setDataOnLoad(): void {
      // Edit
      if (this.materialToEdit) {
        this.material = new MaterialV3(copyObject(this.materialToEdit))
        return
      }

      // New
      this.material = new MaterialV3({ id: this.nextMaterialIDV3 })
    },
    onKeyInput() {
      this.translationError = ''
    },
    onValidation(): boolean {
      let allGood = true

      // Translation
      if (allGood && !this.material.translation) {
        this.translationError = this.mixWB('W: Vælg fra liste')
        allGood = false
      }

      if (
        allGood
        && doesListAlreadyIncludeItem(
          this.material.translation,
          this.materialsAsArrayV3,
          this.material.id,
        )
      ) {
        this.translationError = this.mixWB('W: Denne findes allerede')
        allGood = false
      }

      // Meta data
      if (!this.material.metaData.density.value) {
        this.densityError = this.mixWB('W: Udfyld denne')
        allGood = false
      }

      // Fractions + source
      let isFractionsAllGood = true
      Object.keys(this.material.fractions.fractions).forEach((fractionKey: string) => {
        const fraction: IMaterialFractionV3 = this.material.fractions.fractions[fractionKey]
        if (!fraction.ewcID || !fraction.fractionID) {
          isFractionsAllGood = false
        }
      })

      if (!isFractionsAllGood || !this.material.fractions.source) {
        this.fractionsError = this.mixWB('W: Udfyld alle felter')
        allGood = false
      }

      return allGood
    },
    onSubmit(): void {
      if (this.isLoading) {
        return
      }

      // Validation
      if (!this.onValidation()) {
        return
      }

      this.isLoading = true

      // Create new array
      const newMaterials: MaterialV3[] = []
      this.materialsAsArrayV3.forEach((material: MaterialV3) => {
        if (material.id === this.material.id) {
          return
        }

        newMaterials.push(new MaterialV3({ ...material }))
      })

      // Add new log
      updateLog({
        newItem: this.material,
        oldItem: this.materialToEdit,
        isNew: this.isNew,
      })

      // Add new (or edited) type
      newMaterials.push(this.material)

      // Save type
      this.$store.dispatch('setResourceV3', {
        doc: '-materials-',
        data: sortBy(newMaterials, 'id'),
        shouldBumpID: this.isNew,
      })

      this.$emit('submitted')

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
  components: {
    InputField,
    Button,
    TranslationSelect,
    EWCSelectV3,
    FractionSelectV3,
    Toggle,
    Divider,
  },
  created() {
    this.setDataOnLoad()
  },
})
