
import { defineComponent } from 'vue'
import DialogTitle from '@/components/Modals/DialogTitle.vue'
import CategoryForm from '@/components/Forms/ResourcesV2/CategoryFormV2.vue'

export default defineComponent({
  name: 'NewCategoryDialog',
  methods: {
    onClose() {
      this.$emit('close-dialog')
    },
  },
  components: {
    DialogTitle,
    CategoryForm,
  },
})
