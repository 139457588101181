
import DataTable, { Row } from '@/components/DataTable/DataTable.vue'
import Edit from '@/components/DataTable/Edit.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import NewCategoryDialogV3 from '@/components/Modals/Dialogs/ResourcesV3/NewCategoryDialogV3.vue'
import { CategoryV3 } from '@/globals/javascript/models/resources-version-3/CategoryV3'
import { IAreaV3 } from '@/types/resources-version-3'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CategoriesV3',
  data() {
    return {
      categoryColumns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
        {
          key: 'areas',
          title: this.mixWB('W: Områder'),
          canSort: true,
        },
        {
          key: 'isSummaryCategory',
          title: this.mixWB('W: Har opsummering'),
          canSort: true,
        },
        {
          key: 'useItems',
          title: this.mixWB('W: Brug genstande'),
          canSort: true,
        },
        {
          key: 'isActive',
          title: this.mixWB('W: Status'),
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
        },
      ],
      areaCateogryColumns: [
        {
          key: 'position',
          title: this.mixWB('W: Position'),
          canSort: true,
        },
        {
          key: 'title',
          title: this.mixWB('W: Titel'),
          canSort: true,
        },
        {
          key: 'id',
          title: this.mixWB('W: ID'),
          canSort: true,
        },
      ],

      // Dialog
      showDialog: false,
      dialogProps: {
        categoryToEdit: null,
      },
      NewCategoryDialogV3: shallowRef(NewCategoryDialogV3),
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'categoriesAsArrayV3',
      'areasAsArrayV3',
    ]),
    areaCategories(): { area: IAreaV3, rows: Row[]} {
      return this.areasAsArrayV3.map((area: IAreaV3) => {
        const categories = this.categoriesAsArrayV3.filter(
          (x: CategoryV3) => x.areaIDs.includes(area.id) && x.options.isActive,
        )
        const rows: Row[] = []

        categories.forEach((category: CategoryV3) => {
          rows.push({
            position: category.position,
            title: this.mixWB(category.translation),
            id: category.id,
          })
        })
        return { area, rows }
      })
    },
    categoryRows() {
      const rows: Row[] = []

      this.categoriesAsArrayV3.forEach((category: CategoryV3) => {
        rows.push({
          position: category.position,
          id: category.id,
          title: this.mixWB(category.translation),
          areas: category.areaIDs.length,
          isSummaryCategory: !!category.connectedMaterialIDs.length
            || !!category.connectedTypeIDs.length,
          useItems: category.options.useItems,
          isActive: category.options.isActive,
        })
      })

      return rows
    },
  },
  methods: {
    onUploadV3Data() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('uploadV3Areas')
      this.$store.dispatch('uploadV3Categories')
    },
    onAddCategoryClick() {
      this.showDialog = true
      this.dialogProps.categoryToEdit = null
    },
    onEditCategoryClick(categoryID: string): void {
      const category = this.categoriesAsArrayV3.find((x: CategoryV3) => x.id === categoryID)
      this.dialogProps.categoryToEdit = category
      this.showDialog = true
    },
  },
  components: {
    Page,
    PageTop,
    Button,
    DataTable,
    Edit,
    Dialog,
  },
})
